import { LoadingOutlined } from '@ant-design/icons';
import { QueryClient } from '@tanstack/react-query';
import { Checkbox, Col, Dropdown, Input, Menu, Row, Spin, Tooltip } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getSession, useSession } from 'next-auth/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

import {
  CrossSvg,
  FilterSvg,
  LogoSvg,
  MenuArrowDownSvg,
  SearchSvg,
} from '../../assets/icons';
import ArrowRightSvg from '../../assets/icons/ArrowRightSvg';
import BrainExplosionSvg from '../../assets/icons/BrainExplosionSvg';
import CuripodDevSvg from '../../assets/icons/CuripodDevLogo';
import StackSvg from '../../assets/icons/StackSvg';
import StarStruckSvg from '../../assets/icons/StarStruckSvg';
import theme from '../../assets/theme';
import { Button } from '../../components/_atoms/Button';
import { IconWrapper } from '../../components/_atoms/IconWrapper';
import BaseModal from '../../components/_molecules/BaseModal';
import { NewLandingPageFooter } from '../../components/_molecules/LandingPageFooter';
import LessonCard from '../../components/_organisms/LessonCard';
import NewAppLayout, { TopBarDropdowns } from '../../components/layouts/NewAppLayout';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import useDiscoverFrontPageTemplates, {
  frontPageTemplatesCacheKey,
} from '../../hooks/useDiscoverFrontPageTemplates';
import {
  toSearchParams,
  useDiscoverSearchQueryState,
} from '../../hooks/useDiscoverSearchQueryState';
import useMe from '../../hooks/useMe';
import { useMetrics } from '../../hooks/useMetrics';
import { useQuery } from '../../hooks/useQuery';
import useSignIn from '../../hooks/useSignIn';
import { useTemplateCoverPhoto } from '../../hooks/useTemplateCoverPhoto';
import useTemplateSearch, {
  BASE_TEMPLATE_SEARCH_LIMIT,
} from '../../hooks/useTemplateSearch';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useMarketingMetrics } from '../../providers/PlausibleAnalyticsProvider';
import APILogger from '../../services/APILogger';
import {
  DiscoverSearchQuery,
  IndexedTemplate,
} from '../../services/backendService/types';
import DiscoverService from '../../services/DiscoverService/DiscoverService';
import {
  getDiscoverCreatorUrl,
  getDiscoverLessonUrl,
} from '../../services/DiscoverService/discoverUtils';
import TenantUserService from '../../services/TenantUserService';
import UserTenantsService from '../../services/UserTenantsService';
import { isProduction } from '../../utils/client';
import {
  CheckboxOption,
  GradesMapper,
  LanguageMapper,
  listHotfilters,
} from '../../utils/discoverCategoriesUtils';
import {
  detectAndBestEffortSetLanguage,
  detectLanguage,
} from '../../utils/localisationUtils';
import { isSafari } from '../../utils/navigator';
import {
  dehydrateReactQueryPrefetchedInfiniteQuery,
  toTemplateSearchQueryCacheKey,
} from '../../utils/react-query-utils';
import { provisionFlags } from '../../utils/ServerSideFeatureFlagUtils';
import { zIndexes } from '../../utils/zIndexes';

export const CoverPhotoRenderer = ({
  id,
  coverImageId,
  coverImageAltText,
}: {
  id: string;
  coverImageId: string;
  coverImageAltText?: string | null;
}) => {
  const { thumbnail, isLoading } = useTemplateCoverPhoto(id, coverImageId);

  if (!thumbnail && isLoading) {
    return <div style={{ width: '100%', height: '100%', background: 'white' }}></div>;
  }

  return (
    <PhotoImage
      src={thumbnail}
      decoding="async"
      alt={coverImageAltText ? coverImageAltText : undefined}
    />
  );
};

const PhotoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const ShortLogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 60px;
    width: 80px;
    color: ${theme.colors.orange};
  }
`;

const StyledLink = styled.span`
  font-weight: 500;

  &:hover {
    color: black !important;
  }
`;

export const NewAnonymisedTopbar = ({ isLoadingAuth }: { isLoadingAuth: boolean }) => {
  const router = useRouter();
  const isDesktop = useBreakPoint(1200);
  const { signIn } = useSignIn();
  const { track } = useMarketingMetrics();
  const { t } = useTranslation('Host');
  return (
    <Container>
      <WidthContainer>
        <GroupContainer $includeMargin>
          <ShortLogoContainer onClick={() => router.push('/')}>
            {!isProduction() ? <CuripodDevSvg /> : <LogoSvg />}
          </ShortLogoContainer>
          <TopBarDropdowns />
        </GroupContainer>
        {!isLoadingAuth && (
          <GroupContainer>
            <StyledLink
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                color: theme.colors.black,
                marginRight: 20,
                cursor: 'pointer',
              }}
              onClick={() => signIn()}
            >
              {t('Log in')}
            </StyledLink>

            <Button
              styles={{
                fontWeight: 500,
                fontSize: 18,
              }}
              onClick={() => {
                track('Navigate.SignUp');
                router.push({
                  pathname: '/signup',
                  query: {
                    callback: window.location.href,
                  },
                });
              }}
              type="primary"
            >
              {isDesktop
                ? t('signuplong', `Sign up - It's free`)
                : t('signup', 'Sign up')}
            </Button>
          </GroupContainer>
        )}
      </WidthContainer>
    </Container>
  );
};

export const AnonymisedTopbar = ({ isLoadingAuth }: { isLoadingAuth: boolean }) => {
  const router = useRouter();
  const isDesktop = useBreakPoint(1200);
  const { signIn } = useSignIn();
  const { track } = useMarketingMetrics();
  const { t } = useTranslation('Host');
  return (
    <Container style={{ background: theme.colors.orange }}>
      <WidthContainer>
        <GroupContainer $includeMargin>
          <ShortLogoContainer
            onClick={() => router.push('/')}
            style={{ color: theme.colors.white }}
          >
            {!isProduction() ? <CuripodDevSvg /> : <LogoSvg />}
          </ShortLogoContainer>
        </GroupContainer>
        {!isLoadingAuth && (
          <GroupContainer>
            <StyledLink
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                color: 'white',
                marginRight: 20,
                cursor: 'pointer',
              }}
              onClick={() => signIn()}
            >
              {t('Log in')}
            </StyledLink>

            <Button
              styles={{
                fontWeight: 500,
                fontSize: 18,
              }}
              onClick={() => {
                track('Navigate.SignUp');
                router.push({
                  pathname: '/signup',
                  query: {
                    callback: window.location.href,
                  },
                });
              }}
              type="primary"
            >
              {isDesktop
                ? t('signuplong', `Sign up - It's free`)
                : t('signup', 'Sign up')}
            </Button>
          </GroupContainer>
        )}
      </WidthContainer>
    </Container>
  );
};

const RootGroupContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const GroupContainer = styled(RootGroupContainer)<{
  $includeMargin?: boolean;
}>`
  ${({ $includeMargin }) =>
    $includeMargin &&
    css`
      & > * {
        margin: 0 10px;
      }
    `}

  & > *:last-child {
    margin: 0px !important;
  }
`;

const WidthContainer = styled.div`
  color: white;
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  z-index: ${zIndexes('TOPBAR')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.topbar};
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);
  height: 60px;
  min-height: 60px;
`;

const PageContainer = styled.div<{ $isAuthenticated: boolean }>`
  background-color: ${theme.colors.background};
  height: inherit;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 70px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  @media (min-width: 800px) {
    padding-left: ${({ $isAuthenticated }) => ($isAuthenticated ? '240px' : '0px')};
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const FiltersTopBarButton = styled(Button)<{ $isActive: boolean }>`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  opacity: 1;
  width: 140px;
  color: #a5a5a5;
  padding: 5px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
`;
const FilterTopBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SearchBarDivider = styled.span`
  padding-bottom: 30px;
  background-color: #d9d9d9;
  height: 30px;
  width: 1px;
`;
const FiltersTopBarParentContainer = styled.div<{ $isAuthuthenticated: boolean }>`
  width: ${({ $isAuthuthenticated }) =>
    $isAuthuthenticated ? 'calc(100% - 240px)' : '100%'};
  display: flex;
  position: fixed;
  background-color: ${theme.colors.background};
  top: 60px;
  z-index: ${zIndexes('DISCOVER_FILTERS')};
  box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
  height: 80px;

  @media (max-width: 700px) {
    flex-direction: column;
    height: 130px;
    padding-top: 10px;
  }
  @media (max-width: 800px) {
    width: 100%;
  }

  justify-content: center;
`;

const FiltersControls = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const { t } = useTranslation('Host');
  const {
    addFilter,
    removeFilter,
    setSearchPhrase,
    state,
    clearAllFilters,
    selectAllCategory,
  } = useDiscoverSearchQueryState();
  const { isBusiness } = useMe();
  const searchValue = state?.searchPhrase;
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [localValue, setLocalValue] = useState<string | undefined | null>();
  const handlePressEnter = () => {
    if (localValue !== undefined && localValue !== null) {
      handleSearch(localValue);
    }
  };

  useEffect(() => {
    setLocalValue(searchValue);
  }, [searchValue]);
  const activeLanguageFilterCount = !!state?.filters?.languages
    ? state.filters?.languages.length
    : 0;
  const activeGradesFilterCount = !!state?.filters?.grades
    ? state.filters?.grades?.length
    : 0;
  const activeSubjectsFilterCount = state?.filters?.grades
    ? state.filters?.subjects?.length
    : 0;
  const activeFiltersCount =
    (activeGradesFilterCount ? activeGradesFilterCount : 0) +
    (activeLanguageFilterCount ? activeLanguageFilterCount : 0) +
    (activeSubjectsFilterCount ? activeSubjectsFilterCount : 0) +
    (state?.searchPhrase ? 1 : 0);
  const activeHotFilterValue = !!state?.filters?.hotFilter
    ? state.filters.hotFilter[0]
    : undefined;
  const activeHotFilter = activeHotFilterValue
    ? listHotfilters(t, isBusiness).find(f => f.value === activeHotFilterValue)
    : undefined;

  const isDesktop = useBreakPoint(700);

  const itemClicked = (
    e: React.MouseEvent<HTMLDivElement>,
    filterItem: CheckboxOption,
    filterType: 'subjects' | 'grades' | 'languages' | 'hotFilter',
  ) => {
    // Prevent menu to close when item is clicked
    e.stopPropagation();
    e.preventDefault();
    const filters = state?.filters;
    const relevantFilters = filters ? filters[filterType] : ([] as CheckboxOption[]);
    if (filterType === 'hotFilter') {
      addFilter({ type: filterType, value: filterItem.value });
    }
    const isAlreadyChecked = relevantFilters?.some(item => item === filterItem.value);

    isAlreadyChecked
      ? removeFilter({ type: filterType, value: filterItem.value })
      : addFilter({ type: filterType, value: filterItem.value });
  };

  const handleSearch = (value: string) => {
    setSearchPhrase(value);
    setShowFilterModal(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <BaseModal visible={showFilterModal} onCancel={() => setShowFilterModal(false)}>
        <FilterContainer>
          <FilterTitleContainer>
            {activeFiltersCount > 0 && (
              <ViewMoreTrigger style={{ flex: '0 1 80px' }} onClick={clearAllFilters}>
                {t('Clear filters')}
              </ViewMoreTrigger>
            )}
            <FilterContaienrTitle
              style={activeFiltersCount ? { gridColumn: '2/4' } : { gridColumn: '1/5' }}
            >
              {t('Filter')}
            </FilterContaienrTitle>
          </FilterTitleContainer>
          <SearchBarContainer $active={!!state?.searchPhrase}>
            <IconWrapper>
              <SearchSvg />
            </IconWrapper>
            <DiscoverInput
              autoFocus={false}
              placeholder={t('Find a lesson about')}
              value={localValue !== null ? localValue : undefined}
              type={'search'}
              onPressEnter={handlePressEnter}
              onChange={e => setLocalValue(e.target.value)}
            ></DiscoverInput>
            {state?.searchPhrase && (
              <Button
                type="ghost"
                styles={{ border: 'none', padding: '5px' }}
                onClick={() => setSearchPhrase('')}
              >
                <IconWrapper>
                  <CrossSvg />
                </IconWrapper>
              </Button>
            )}
          </SearchBarContainer>
          <FilterCheckboxContainer>
            <p>{t('Categories')}</p>
            {listHotfilters(t, isBusiness).map(filterItem => (
              <div
                key={filterItem.value}
                style={{ width: 'fit-content', padding: '5px 12px' }}
                onClick={e => {
                  itemClicked(e, filterItem, 'hotFilter');
                }}
              >
                <Checkbox
                  checked={state?.filters?.hotFilter?.some(
                    item => item === filterItem.value,
                  )}
                >
                  {filterItem.name}
                </Checkbox>
              </div>
            ))}
          </FilterCheckboxContainer>
          <FilterCheckboxContainer>
            <p>{t('Grades')}</p>
            {GradesMapper(t).map(filterItem => (
              <div
                key={filterItem.value}
                style={{ width: 'fit-content', padding: '5px 12px' }}
                onClick={e => itemClicked(e, filterItem, 'grades')}
              >
                <Checkbox
                  checked={state?.filters?.grades?.some(
                    item => item === filterItem.value,
                  )}
                  style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
                >
                  {filterItem.name}
                </Checkbox>
              </div>
            ))}
          </FilterCheckboxContainer>
          <FilterCheckboxContainer>
            <p>{t('Languages')}</p>
            {LanguageMapper(t).map(filterItem => (
              <div
                key={filterItem.value}
                style={{ width: 'fit-content', padding: '5px 12px' }}
                onClick={e => itemClicked(e, filterItem, 'languages')}
              >
                <Checkbox
                  checked={state?.filters?.languages?.some(
                    item => item === filterItem.value,
                  )}
                >
                  {filterItem.name}
                </Checkbox>
              </div>
            ))}
          </FilterCheckboxContainer>

          <FilterButton
            onClick={() => {
              handlePressEnter();
              setShowFilterModal(false);
            }}
          >
            {t('Search')}
          </FilterButton>
        </FilterContainer>
      </BaseModal>
      <FiltersTopBarParentContainer $isAuthuthenticated={isAuthenticated}>
        {!isDesktop && (
          <FilterTopBarContainer>
            <FiltersTopBarButton
              type="ghost"
              onClick={() => setShowFilterModal(true)}
              $isActive={activeFiltersCount > 0}
            >
              {activeFiltersCount > 0 && (
                <FilterCountIcon>{activeFiltersCount}</FilterCountIcon>
              )}
              <IconWrapper height="20px" width="20px">
                <SearchSvg />
              </IconWrapper>
              <SearchBarDivider />
              <IconWrapper height="20px" width="20px">
                <FilterSvg />
              </IconWrapper>
            </FiltersTopBarButton>
            {activeFiltersCount > 0 && (
              <ViewMoreTrigger style={{ flex: '0 1 80px' }} onClick={clearAllFilters}>
                {t('Clear filters')}
              </ViewMoreTrigger>
            )}
          </FilterTopBarContainer>
        )}

        <HotFilterContainer>
          <HotFilterItemContainer
            key={'all'}
            onClick={e => {
              activeHotFilter
                ? itemClicked(e, activeHotFilter, 'hotFilter')
                : selectAllCategory();
              window.scrollTo(0, 0);
            }}
            $selected={!state?.filters?.hotFilter}
          >
            <IconWrapper height={'24px'} width={'24px'}>
              <StackSvg />
            </IconWrapper>
            <HotFilterTitle>{isBusiness ? 'All' : t('All')}</HotFilterTitle>
          </HotFilterItemContainer>
          {listHotfilters(t, isBusiness).map(filter => (
            <Tooltip
              title={filter.toolTip}
              mouseEnterDelay={1}
              placement="bottom"
              key={filter.value}
            >
              <HotFilterItemContainer
                key={filter.value}
                onClick={e => itemClicked(e, filter, 'hotFilter')}
                $selected={!!state?.filters?.hotFilter?.includes(filter.value)}
              >
                <IconWrapper height={'24px'} width={'24px'}>
                  {filter.icon}
                </IconWrapper>
                <HotFilterTitle>{filter.name}</HotFilterTitle>
              </HotFilterItemContainer>
            </Tooltip>
          ))}
        </HotFilterContainer>
        {isDesktop && (
          <FilterTopBarContainer style={{ marginRight: '20px' }}>
            <FiltersTopBarButton
              type="ghost"
              onClick={() => setShowFilterModal(true)}
              $isActive={activeFiltersCount > 0}
            >
              {activeFiltersCount > 0 && (
                <FilterCountIcon>{activeFiltersCount}</FilterCountIcon>
              )}
              <IconWrapper height="20px" width="20px">
                <SearchSvg />
              </IconWrapper>
              <SearchBarDivider />
              <IconWrapper height="20px" width="20px">
                <FilterSvg />
              </IconWrapper>
            </FiltersTopBarButton>
            {activeFiltersCount > 0 && (
              <ViewMoreTrigger
                style={{ flex: '0 1 80px', marginLeft: 5 }}
                onClick={clearAllFilters}
              >
                {t('Clear filters')}
              </ViewMoreTrigger>
            )}
          </FilterTopBarContainer>
        )}
      </FiltersTopBarParentContainer>
    </div>
  );
};

const FilterContaienrTitle = styled.h1`
  margin: 0;
`;
const FilterTitleContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column: 1/3;

  @media (max-width: 800px) {
    grid-column: 1/2;
  }
`;
const FilterButton = styled(Button)`
  margin-top: 20px;
  grid-column: 1/3;
  @media (max-width: 800px) {
    grid-column: 1/2;
  }
`;

const DiscoverInput = styled(Input)`
  width: 100%;
  border: none;
  padding: none;

  &:focus {
    box-shadow: none;
  }
  input::placeholder {
    font-size: 10px !important;
  }
  input {
    height: auto;
  }
`;
const HotFilterTitle = styled.p`
  padding-top: 0px;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 0px;
  display: inline-flex;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  flex: 1;
  font-weight: bold;
`;
const FilterCountIcon = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  z-index: ${zIndexes('DISCOVER_FILTERS_COUNT')};
  background-color: ${theme.colors.blue};
  width: 20px;
  color: white;
  font-size: 12px;
`;

const FilterContainer = styled.div`
  display: grid;
  padding: 20px;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

const FilterCheckboxContainer = styled.div`
  padding: 10px;
  color: #a5a5a5;
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  p {
    margin: 0;
  }
`;
const SearchBarContainer = styled.div<{ $active: boolean }>`
  background-color: white;
  border-radius: 10px;
  border: none;
  display: flex;
  padding: 5px;
  padding-left: 20px;
  grid-column: 1/3;
  @media (max-width: 800px) {
    grid-column: 1/2;
  }
`;
const HotFilterContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 50px;
  grid-auto-flow: column;
  overflow-x: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  height: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const HotFilterItemContainer = styled.div<{ $selected: boolean }>`
  padding: 10px 10px 5px 10px;
  /* margin-right: 5px;
  margin-left: 5px; */
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  opacity: ${({ $selected }) => ($selected ? 0.8 : 0.6)};
  &:hover {
    opacity: 1;
    border-bottom: 2px solid #a5a5a5;
  }
  border-bottom: ${({ $selected }) =>
    $selected ? `2px solid ${theme.colors.black} !important` : '2px solid transparent'};

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  span {
    text-align: center;
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`;
const ContentRow = ({
  titleKey,
  title,
  items,
  onCardClicked,
  onClickViewMore,
}: {
  titleKey: string;
  title: string;
  items: IndexedTemplate[];
  onCardClicked: (templateId: string) => void;
  onClickViewMore?: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('Host');
  const [windowWidth] = useWindowSize();
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const cardListGap = 10;
  const cardWidth =
    windowWidth >= 800 ? 336 : windowWidth >= 600 ? 270 : windowWidth >= 400 ? 200 : 100;

  const checkScreenWidth = () => {
    if (ref.current) {
      const { scrollWidth, offsetWidth } = ref.current;
      if (offsetWidth >= scrollWidth) {
        setRightArrowDisabled(true);
        setLeftArrowDisabled(true);
      } else {
        setRightArrowDisabled(false);
      }
    }
  };

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  const handleLeftClick = () => {
    if (ref.current) {
      const itemsToScroll = 2;
      const { scrollLeft } = ref.current;

      const itemScrolledRemaining = scrollLeft % (cardWidth + cardListGap);
      const nextLeftScrollPosition =
        itemScrolledRemaining === 0
          ? scrollLeft - (cardWidth + cardListGap) * itemsToScroll
          : scrollLeft - itemScrolledRemaining;
      const hasNextScroll = nextLeftScrollPosition > 0;
      if (hasNextScroll) {
        setLeftArrowDisabled(false);
      } else {
        setLeftArrowDisabled(true);
      }
      ref.current.scroll({ left: nextLeftScrollPosition, behavior: 'smooth' });
    }
    setRightArrowDisabled(false);
  };

  const handleRightClick = () => {
    if (ref.current) {
      const itemsToScroll = 2;
      const { scrollLeft, scrollWidth, offsetWidth } = ref.current;

      const nextLeftScrollPosition =
        scrollLeft + (cardWidth + cardListGap) * itemsToScroll;
      const hasNextScroll = offsetWidth + nextLeftScrollPosition <= scrollWidth;
      if (hasNextScroll) {
        setRightArrowDisabled(false);
      } else {
        setRightArrowDisabled(true);
      }
      ref.current.scroll({ left: nextLeftScrollPosition, behavior: 'smooth' });
    }
    setLeftArrowDisabled(false);
  };

  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      style={{
        marginBottom: '40px',
      }}
    >
      <ContentHeaderContainer>
        <h2 style={{ fontWeight: 'bold', fontSize: 16 }}>
          {t(titleKey, { defaultValue: title })}
        </h2>
        {onClickViewMore && (
          <ViewMoreTrigger onClick={onClickViewMore}>{t('view more')}</ViewMoreTrigger>
        )}
      </ContentHeaderContainer>
      <LessonsContainer>
        <ArrowButton
          $align={'left'}
          onClick={handleLeftClick}
          $visible={isHovering}
          $disabled={leftArrowDisabled}
        >
          <ArrowRightSvg />
        </ArrowButton>
        <ScrollableArea
          style={isSafari ? { overflow: 'scroll' } : {}}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          ref={ref}
        >
          {items.map(item => {
            return (
              <ScrollResponsiveLessonCardContainer key={item.id}>
                <LessonCard
                  template={item}
                  onClick={onCardClicked}
                  link={getDiscoverLessonUrl({
                    templateName: item.title,
                    templateId: item.id,
                  })}
                  authorLink={getDiscoverCreatorUrl(
                    item.creatorName,
                    item.creatorProfileId,
                  )}
                />
              </ScrollResponsiveLessonCardContainer>
            );
          })}
        </ScrollableArea>
        <ArrowButton
          $align={'right'}
          onClick={handleRightClick}
          $visible={isHovering}
          $disabled={rightArrowDisabled}
        >
          <ArrowRightSvg />
        </ArrowButton>
      </LessonsContainer>
    </div>
  );
};

const ScrollResponsiveLessonCardContainer = styled.div`
  height: 220px;

  .lessonCardContainer {
    width: 310px;
  }

  @media (max-width: 800px) {
    width: 310px;
    height: 220px;
    .lessonCardContainer {
      width: 280px;
    }
  }
  @media (max-width: 600px) {
    width: 280px;
    height: 220px;
    .lessonCardContainer {
      width: 280px;
    }
  }
  @media (max-width: 400px) {
    width: 220px;
    height: 200px;
    .lessonCardContainer {
      width: 220px;
    }
  }
`;

const ArrowButton = styled.button<{
  $align?: 'right' | 'left';
  $visible: boolean;
  $disabled: boolean;
}>`
  position: absolute;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 0px;
  border-radius: 50px;
  justify-self: center;
  top: 50%;
  z-index: ${zIndexes('DISCOVER_BROWSE_ARROW')};
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #a5a5a5;
  outline: none;
  color: ${theme.colors.black};
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;

  ${({ $visible }) => $visible && 'opacity: 1'};

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  ${({ $disabled }) => $disabled && 'opacity: 0'};
  ${({ $align }) =>
    $align === 'left'
      ? css`
          left: 10px;
          svg {
            transform: rotate(180deg);
            margin-right: 3px;
          }
        `
      : css`
          right: 10px;
          margin-left: 3px;
        `}
  svg {
    width: 20px;
    height: 20px;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

const LessonsContainer = styled.div`
  position: relative;
`;

const ScrollableArea = styled.div`
  position: relative;
  padding-left: 40px;
  @media (max-width: 600px) {
    padding-left: 10px;
  }
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;

  display: flex;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
  & > *:not(:last-child) {
    margin-right: 10px;
  }

  overflow: scroll;
  scroll-behavior: smooth;

  -webkit-overflow-scrolling: touch;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (hover: none) and (pointer: coarse) {
    overflow: scroll;
  }
`;

const ContentHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 40px;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const ViewMoreTrigger = styled.p`
  font-weight: bold;
  color: ${theme.colors.black};
  font-size: 14;
  margin-bottom: 0;
  white-space: nowrap;

  display: flex;
  justify-content: center;
  text-decoration: underline;
  @media (max-width: 600px) {
    font-size: 12px;
  }
  cursor: pointer;
  &:hover {
    font-weight: 800;
  }
`;

const ExploreContent = ({ lang }: { lang?: string }) => {
  const { result } = useDiscoverFrontPageTemplates();
  const { state } = useDiscoverSearchQueryState(lang);
  const {
    items: allResults,
    canFetchMore,
    isLoading,
    fetchMore,
    isFetchingMore,
  } = useTemplateSearch(state);
  const metrics = useMetrics();

  const { addFilter } = useDiscoverSearchQueryState();
  const { ref: scrollRef, inView } = useInView();

  const handleCardClicked = useCallback(
    (templateId: string) => {
      metrics.logEvent('DiscoverPage.Template.Open', { templateId, query: '/explore' });
    },
    [metrics],
  );
  useEffect(() => {
    if (inView && canFetchMore && !isFetchingMore) {
      fetchMore();
    }
  }, [inView, canFetchMore, fetchMore, isFetchingMore]);

  return (
    <ExploreContainer>
      <div style={{ width: '100%', paddingBottom: 10 }}>
        {result?.orderedGroups.map(group => {
          return (
            <ContentRow
              key={group.id}
              items={result.data[group.id]}
              title={group.name}
              titleKey={group.id}
              onCardClicked={handleCardClicked}
              onClickViewMore={
                group.id !== 'whatsnew'
                  ? () => {
                      metrics.logEvent('DiscoverPage.Explore.ViewMore', {
                        value: group.id,
                      });
                      addFilter({ type: 'subjects', value: group.id });
                    }
                  : undefined
              }
            />
          );
        })}
      </div>

      <AllLessonsContainer>
        {allResults?.pages.flatMap(res =>
          res.items.map(item => (
            <LessonCard
              key={item.id}
              link={getDiscoverLessonUrl({
                templateName: item.title,
                templateId: item.id,
              })}
              authorLink={getDiscoverCreatorUrl(item.creatorName, item.creatorProfileId)}
              template={item}
              onClick={() => {
                handleCardClicked(item.id);
              }}
            />
          )),
        )}
      </AllLessonsContainer>
      {canFetchMore && (
        <CenteredContainer style={{ marginTop: 40 }}>
          {isLoading && (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          )}
        </CenteredContainer>
      )}
      <div ref={scrollRef}></div>
    </ExploreContainer>
  );
};

const AllLessonsContainer = styled.div`
  display: grid;
  counter-reset: grid-items;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-auto-rows: 220px;
  grid-gap: 20px;
  justify-content: space-between;

  /* boring properties */
  list-style: none;

  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 750px) {
    justify-content: center;
    grid-template-columns: minmax(250px, 500px);
    grid-auto-rows: 250px;
  }
  &::before {
    position: absolute;
    padding-left: 3em;
    content: attr(data-keyword);
  }
`;

const BaseDiscoverContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 330px);
`;

const ContentContainer = styled(BaseDiscoverContainer)`
  padding-bottom: 40px;
  padding-top: 100px;
  @media (max-width: 700px) {
    padding-top: 140px;
  }
`;

const ExploreContainer = styled(BaseDiscoverContainer)`
  padding-top: 100px;
  padding-bottom: 40px;

  @media (max-width: 600px) {
    padding-bottom: 10px;
  }
  @media (max-width: 700px) {
    padding-top: 140px;
  }
`;

const EmptyStateContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 30px;
  h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #a5a5a5;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmojiWrapper = styled.div`
  display: flex;
  svg {
    height: 100px;
    width: 100px;
  }
`;

const EmptyState = ({ isHotFilter }: { isHotFilter?: boolean }) => {
  const { t } = useTranslation('Host');
  return (
    <EmptyStateContainer>
      <CenteredContainer>
        <EmojiWrapper>
          {isHotFilter ? <StarStruckSvg /> : <BrainExplosionSvg />}
        </EmojiWrapper>
      </CenteredContainer>
      <div style={{ maxWidth: 400, margin: 'auto' }}>
        <h3
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 0,
            fontSize: 22,
            color: theme.colors.black,
          }}
        >
          {isHotFilter
            ? t('More lessons are coming soon')
            : t('Sorry, no results found at the moment')}
        </h3>
        <p
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: 10,
            fontSize: 16,
            color: '#828282',
            fontWeight: 'bold',
          }}
        >
          {isHotFilter
            ? t('Come back in a few days to see our latest lessons')
            : t('Perhaps try searching something else?')}
        </p>
      </div>
    </EmptyStateContainer>
  );
};

const QueryContent = () => {
  const metrics = useMetrics();
  const { state } = useDiscoverSearchQueryState();
  const { items, fetchMore, canFetchMore, isLoading } = useTemplateSearch(state);
  const handleCardClicked = useCallback(
    (templateId: string) => {
      metrics.logEvent('DiscoverPage.Template.Open', {
        templateId,
        query: state ? toSearchParams(state).toString() : '',
        itemIndex: items?.pages
          .flatMap(page => page.items)
          .findIndex(item => item.id === templateId),
        totalItems: items ? items.pages[0].total : 0,
      });
    },
    [items, state, metrics],
  );

  const isHotFilter = !!(
    state?.filters?.hotFilter && state?.filters?.hotFilter?.length > 0
  );

  const handleFetchMore = () => {
    fetchMore();
  };

  return (
    <ContentContainer>
      <ResponsiveResults>
        {!isLoading && items && items.pages[0].total === 0 && (
          <EmptyState isHotFilter={isHotFilter} />
        )}
        <Row gutter={[20, 20]}>
          {items?.pages.flatMap(res =>
            res.items.map(item => (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={6}
                xxl={6}
                style={{ height: 250 }}
                key={item.id}
              >
                <LessonCard
                  key={item.id}
                  link={getDiscoverLessonUrl({
                    templateName: item.title,
                    templateId: item.id,
                  })}
                  authorLink={getDiscoverCreatorUrl(
                    item.creatorName,
                    item.creatorProfileId,
                  )}
                  template={item}
                  onClick={() => {
                    handleCardClicked(item.id);
                  }}
                />
              </Col>
            )),
          )}
        </Row>
      </ResponsiveResults>
      {canFetchMore && (
        <CenteredContainer style={{ marginTop: 40 }}>
          {!isLoading && (
            <Button type="ghost" onClick={handleFetchMore} loading={isLoading}>
              Load more
            </Button>
          )}
          {isLoading && (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          )}
        </CenteredContainer>
      )}
    </ContentContainer>
  );
};

const ResponsiveResults = styled.div`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const DiscoverPage: NextPage = ({ lang }: { lang?: string }) => {
  const { nextQuery } = useQuery();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pageContainerRef = useRef<any>();
  const hasQuery =
    JSON.stringify(nextQuery).length > 2 &&
    (nextQuery.subjects !== undefined ||
      nextQuery.grades !== undefined ||
      nextQuery.languages !== undefined ||
      nextQuery.hotFilter !== undefined ||
      nextQuery.search !== undefined ||
      nextQuery.limit !== undefined ||
      nextQuery.cursor !== undefined);
  const session = useSession();
  const isAuthenticated = session.status === 'authenticated';
  useEffect(() => {
    if (pageContainerRef?.current) pageContainerRef.current.scrollTo(0, 0);
  }, [nextQuery]);
  return (
    <>
      <Head>
        <title>Discover free interactive learning lessons at Curipod | Curipod</title>
        <meta
          property="og:title"
          content="Discover free interactive learning lessons at Curipod | Curipod"
          key="title"
        />
        <meta
          property="description"
          content="Check out ready to play lessons from Verified educators and authors. All ready to play for free!"
          key="description"
        />
        <meta
          name="description"
          content="Check out ready to play lessons from Verified educators and authors. All ready to play for free!"
          key="namedescription"
        />
      </Head>
      <NewAppLayout allowUnAuthenticated={true}>
        <PageContainer ref={pageContainerRef} $isAuthenticated={isAuthenticated}>
          <FiltersControls isAuthenticated={isAuthenticated} />
          {hasQuery && <QueryContent />}
          {!hasQuery && <ExploreContent lang={lang} />}
          <NewLandingPageFooter footerBackgroundColor={theme.colors.paleBeige} />
        </PageContainer>
      </NewAppLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async context => {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=300, stale-while-revalidate=3600',
  );
  const lang = detectLanguage(context, new APILogger());
  const queryClient = new QueryClient();
  if (
    JSON.stringify(context.query).length > 2 &&
    (context.query.subjects !== undefined ||
      context.query.grades !== undefined ||
      context.query.hotFilter !== undefined ||
      context.query.languages !== undefined ||
      context.query.search !== undefined ||
      context.query.limit !== undefined ||
      context.query.cursor !== undefined)
  ) {
    const q: DiscoverSearchQuery = {
      filters: {
        subjects: (context.query.subjects as string | undefined)?.split(','),
        grades: (context.query.grades as string | undefined)?.split(','),
        hotFilter: (context.query.hotFilter as string | undefined)?.split(','),
        languages: (context.query.languages as string | undefined)?.split(','),
      },
      searchPhrase: context.query.search as string | undefined,
      cursor: context.query.cursor ? Number(context.query.cursor) : 0,
      limit: Number(context.query.limit || BASE_TEMPLATE_SEARCH_LIMIT),
      sort:
        context.query.search === undefined
          ? [{ downloadCount: { order: 'desc' } }]
          : undefined,
    };

    await queryClient.prefetchInfiniteQuery(
      ['template-search', toTemplateSearchQueryCacheKey(q)],
      async ({ pageParam = 0 }: { pageParam?: number }) => {
        const res = await DiscoverService.getDiscoveredTemplates({
          query: {
            ...q,
            cursor: pageParam,
          },
        });
        return res;
      },
      {
        getNextPageParam: lastPage => lastPage.cursor || null,
        staleTime: 60 * 60,
      },
    );

    return {
      props: {
        dehydratedState: dehydrateReactQueryPrefetchedInfiniteQuery(queryClient),
        ...(await detectAndBestEffortSetLanguage(context)),
      },
    };
  } else {
    const session = await getSession(context);

    if (!session) {
      await queryClient.prefetchQuery(
        frontPageTemplatesCacheKey,
        async () => DiscoverService.getFrontPageTemplates(lang),
        { staleTime: 60 * 60 },
      );
    } else {
      const globalUser = await UserTenantsService.get(session.user.uid);
      const user = await TenantUserService.get(
        session.user.uid,
        globalUser.activeTenantId,
      );
      if (user?.accountType === 'professional') {
        await queryClient.prefetchQuery(
          frontPageTemplatesCacheKey,
          async () => DiscoverService.getProfessionalFrontPageTemplates(),
          { staleTime: 60 * 60 },
        );
      } else {
        await queryClient.prefetchQuery(
          frontPageTemplatesCacheKey,
          async () => DiscoverService.getFrontPageTemplates(lang),
          { staleTime: 60 * 60 },
        );
      }
    }

    // The following code is the prefetching of all templates on the discover frontpage
    const supportedLanguages = ['en', 'nb-NO'];
    const languageToUse = lang && supportedLanguages.includes(lang) ? lang : undefined;

    const q: DiscoverSearchQuery = {
      cursor: 0,
      sort: [{ downloadCount: { order: 'desc' } }],
      limit: BASE_TEMPLATE_SEARCH_LIMIT,
      filters: {
        languages: languageToUse ? [languageToUse] : undefined,
      },
    };

    await queryClient.prefetchInfiniteQuery(
      ['template-search', toTemplateSearchQueryCacheKey(q)],
      async ({ pageParam = 0 }: { pageParam?: number }) => {
        const res = await DiscoverService.getDiscoveredTemplates({
          query: {
            ...q,
            cursor: pageParam,
          },
        });
        return res;
      },
      {
        getNextPageParam: lastPage => lastPage.cursor || null,
        staleTime: 60 * 60,
      },
    );
    return {
      props: {
        ...(await provisionFlags(context, queryClient, session)),
        lang: languageToUse, // passing this to the client so we dont need to check the ip-adress language for the react query cache key in AllLessonContainer
        dehydratedState: dehydrateReactQueryPrefetchedInfiniteQuery(queryClient),
        ...(await detectAndBestEffortSetLanguage(context)),
        session,
      },
    };
  }
};

export default DiscoverPage;
