import { useMutation, useQueryClient } from '@tanstack/react-query';

import backendService from '../services/backendService';
import { PresentationDTO } from '../services/backendService/types';
import { Template } from '../services/TemplateService/Template.type';
import { deletedTemplatesCacheKey } from './useDeletedTemplates';
import { deletedWorkspaceTemplatesCacheKey } from './useDeletedWorkspaceTemplates';
import { useMetrics } from './useMetrics';
import { presentationsCacheKey } from './usePresentations';
import { presentationsSearchCacheKey } from './usePresentationsSearch';
import { recentTemplatesCacheKey } from './useRecentTemplates';

export const useDeleteTemplate = ({
  currentCacheKey,
}: {
  currentCacheKey?: (string | boolean | undefined)[];
}) => {
  const metrics = useMetrics();
  const client = useQueryClient();
  const { mutate: deleteTemplate, isLoading: isDeletingTemplate } = useMutation(
    async ({ id }: { id: string }) => {
      metrics.logEvent('Template.Delete', { id });
      return await backendService.instance.template.deleteTemplate(id);
    },
    {
      onSettled: () => {
        client.invalidateQueries(currentCacheKey);
        client.invalidateQueries([deletedTemplatesCacheKey]);
        client.invalidateQueries([deletedWorkspaceTemplatesCacheKey]);
        client.invalidateQueries([presentationsCacheKey]);
        client.invalidateQueries([recentTemplatesCacheKey]);
        client.invalidateQueries([presentationsSearchCacheKey]);
      },
      onMutate: async template => {
        await client.cancelQueries(currentCacheKey);
        if (currentCacheKey) {
          client.setQueryData<any | undefined>(
            currentCacheKey,
            (
              templates:
                | {
                    pages: { items: PresentationDTO[]; cursor?: string }[];
                    pageParams: (string | undefined)[];
                  }
                | Template[]
                | undefined,
            ) => {
              if (!templates) return templates;
              if (
                (templates as {
                  pages: { items: PresentationDTO[]; cursor?: string }[];
                  pageParams: (string | undefined)[];
                }).pages
              ) {
                const t = templates as {
                  pages: { items: PresentationDTO[]; cursor?: string }[];
                  pageParams: (string | undefined)[];
                };
                // this is a big paginated query
                return {
                  pages: t.pages.map(page => ({
                    ...page,
                    items: page.items.filter(
                      (t: PresentationDTO) => t.id !== template.id,
                    ),
                  })),
                  pageParams: t.pageParams,
                };
              } else {
                // Normal list query
                if (!templates) return templates;
                return (templates as Template[]).filter(t => t.id !== template.id);
              }
            },
          );
        }
      },
      onError: (err, bodyWithError, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );

  return {
    deleteTemplate,
    isDeletingTemplate,
  };
};
