import { useFlag } from '@unleash/proxy-client-react';
import Link from 'next/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

import EnterprisePlanIcon from '../../../assets/icons/EnterprisePlanIcon';
import theme from '../../../assets/theme';
import { useMetrics } from '../../../hooks/useMetrics';
import usePaywallPrompt from '../../../hooks/usePaywallPrompt';
import CountrySchoolSelectionPopup from '../../../pages/CountrySchoolSelectionPopup';
import { IconWrapper } from '../../_atoms/IconWrapper';

const wiggle = keyframes`
  0%, 80% { transform: scale(1) rotate(0deg); }
  20% { transform: scale(1.2) rotate(0deg); }
  24% { transform: scale(1.2) rotate(-5deg); }
  28% { transform: scale(1.2) rotate(5deg); }
  32% { transform: scale(1.2) rotate(0deg); }
  60% { transform: scale(1) rotate(0deg); }
`;

const HighlightedDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.orange};
  border-radius: 5px;
  animation: ${wiggle} 3.125s infinite;
  padding: 5px;
`;

const PlanIndicator = ({ isMobileDrawer }: { isMobileDrawer: boolean }) => {
  const {
    isReady,
    promptPaywall,
    paywallComponent,
    tenantPlan,
    tenantName,
  } = usePaywallPrompt({
    startState: { screen: 'schoolPlanPaywall' },
  });
  const metrics = useMetrics();
  const { t } = useTranslation('Host');

  const [showTrialPopup, setShowTrialPopup] = useState(false);
  const [popupKey, setPopupKey] = useState(0);

  const handleShowPaywall = () => {
    if (isReady) {
      promptPaywall({ label: 'Upgrade account' });
    }
  };

  return (
    <Link href={tenantPlan?.isTrial() ? '/school-plan-trial' : '/profile'} passHref>
      <div
        style={{
          width: '100%',
        }}
      >
        {paywallComponent}
        <div>
          <span style={{ fontSize: 16 }}>
            {tenantPlan?.isFreemium()
              ? 'Personal'
              : tenantPlan?.isPremium()
              ? 'Premium'
              : tenantPlan?.isTrial()
              ? 'School Plan Trial'
              : tenantName}
          </span>
        </div>
        <PointedRow $isMobileDrawer={isMobileDrawer}>
          {isReady && (
            <>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                {tenantPlan?.label}
              </span>
              <div
                style={{
                  height: 3,
                  width: 3,
                  background: theme.colors.greyDark,
                  borderRadius: 20,
                }}
              ></div>
              {(tenantPlan?.isFreemium() || tenantPlan?.isPremium()) && (
                <HighlightedDiv style={{ cursor: 'pointer' }} onClick={handleShowPaywall}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      lineHeight: '14px',
                      fontSize: 12,
                      textDecoration: 'underline',
                      color: theme.colors.white,
                      marginRight: 5,
                    }}
                  >
                    {t('Upgrade')}
                  </span>
                  <IconWrapper height="10px" width="10px" color={theme.colors.yellow}>
                    <EnterprisePlanIcon color={theme.colors.white} />
                  </IconWrapper>
                </HighlightedDiv>
              )}
              {(tenantPlan?.isEnterprise() || tenantPlan?.isPremium()) && (
                <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                  {t('Unlimited lessons')}
                </span>
              )}
            </>
          )}
        </PointedRow>
        {showTrialPopup && <CountrySchoolSelectionPopup key={popupKey} />}
      </div>
    </Link>
  );
};

export default PlanIndicator;

const PointedRow = styled.div<{ $isMobileDrawer: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;
  height: 20px;

  ${({ $isMobileDrawer }) =>
    $isMobileDrawer
      ? css`
          color: ${theme.colors.white};
        `
      : css`
          color: ${theme.colors.greyDark};
        `}
`;
