import { Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import theme from '../../../assets/theme';
import useMe from '../../../hooks/useMe';
import { Input } from '../../_atoms/_Input';
import { Button } from '../../_atoms/Button';

const NickNameModal = ({ visible, onDone }: { visible: boolean; onDone: () => void }) => {
  const [name, setName] = useState<string>();
  const { me, setNickName, setNicknameSuccess } = useMe();
  const nick = me?.username;
  const { t } = useTranslation('Host');

  useEffect(() => {
    if (nick) {
      setName(nick);
    }
  }, [nick]);

  const handleSubmit = () => {
    if (name) {
      setNickName(name);
    }
  };

  useEffect(() => {
    if (setNicknameSuccess) {
      onDone();
    }
  }, [setNicknameSuccess, onDone]);

  return (
    <ModalStyled
      visible={visible}
      centered
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
      onCancel={() => onDone()}
      onOk={() => onDone()}
    >
      <ModalContainer>
        <h1>{t('Change nickname')}</h1>
        <Input
          autoFocus
          onChange={e => setName(e.target.value.slice(0, 20))}
          value={name}
          onPressEnter={handleSubmit}
          style={{ marginBottom: 20, width: '100%' }}
        />
      </ModalContainer>
      <Row>
        <Col span={12}>
          <Button
            styles={{ width: '100%', borderRadius: '0px 10px 0px 0px' }}
            height="60px"
            type="secondary"
            onClick={() => onDone()}
          >
            {t('Cancel')}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            styles={{ width: '100%', borderRadius: '10px 0px 10px 0px' }}
            htmlType="submit"
            type="primary"
            height="60px"
            onClick={handleSubmit}
          >
            {t('Update')}
          </Button>
        </Col>
      </Row>
    </ModalStyled>
  );
};

export default NickNameModal;

const ModalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  h1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: none;
  }
  .ant-modal-body {
    box-shadow: none;
    border-radius: 10px;
    background-color: ${theme.colors.background};
  }
`;
