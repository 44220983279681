import styled, { css } from 'styled-components';

export const ProfileCircle = styled.div<{
  $color?: string;
  $background?: string;
  $radius?: number;
  $showHoverAnimation?: boolean;
  onClick?: () => void;
}>`
  ${({ $radius = 40 }) => css`
    border-radius: ${$radius / 1}px;
    height: ${$radius}px;
    width: ${$radius}px;
  `};
  background-color: ${({ $background }) => $background};
  color: ${({ $color = 'white' }) => $color};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  ${({ onClick }) => onClick && 'cursor: pointer'};
  transition: 0.5s;
  ${({ $showHoverAnimation }) =>
    $showHoverAnimation &&
    `&:hover {
    img {
      filter: brightness(80%);
    }
    `}
`;
