import { useQuery, useQueryClient } from '@tanstack/react-query';

import BackendService from '../services/backendService';
export const recentTemplatesCacheKey = 'recentTemplates';

export const createRecentTemplatesCachekey = (isPrivate: boolean) => [
  recentTemplatesCacheKey,
  isPrivate,
];

function useRecentTemplates({
  limit = 5,
  isPrivate,
}: {
  limit: number;
  isPrivate?: boolean;
}) {
  const cacheKey = [recentTemplatesCacheKey, isPrivate];
  const client = useQueryClient();

  const { data: templates, isFetching: isFetchingTemplates } = useQuery(
    cacheKey,
    async _ => {
      const templates = await BackendService.instance.template.listRecentTemplates({
        limit,
        isPrivate,
      });
      if (templates) {
        templates.items.forEach(template => {
          client.setQueryData(['template', template.id], template);
        });
      }
      return templates;
    },

    { staleTime: 1000 * 60 * 5 },
  );

  return {
    templates: templates?.items,
    isFetchingTemplates,
  };
}
export default useRecentTemplates;
