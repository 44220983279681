import React from 'react';

export const TrainingSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.778354 6.63C0.306687 6.36916 0.041687 5.9125 0.041687 5.435C0.041687 4.95666 0.306687 4.5 0.778354 4.24L7.70669 0.41083C8.49335 -0.0233366 9.50669 -0.0233366 10.2934 0.41083L17.2217 4.24C17.6934 4.5 17.9584 4.95666 17.9584 5.435C17.9584 5.9125 17.6934 6.36916 17.2217 6.63L10.2934 10.4583C9.50669 10.8933 8.49335 10.8933 7.70669 10.4583L0.778354 6.63ZM1.38252 5.53583L8.31085 9.36416C8.73002 9.59583 9.27002 9.59583 9.68919 9.36416L16.6175 5.53583C16.6634 5.51083 16.7084 5.48083 16.7084 5.435C16.7084 5.38833 16.6634 5.35916 16.6175 5.33333L9.68919 1.505C9.27002 1.27333 8.73002 1.27333 8.31085 1.505L1.38252 5.33333C1.33669 5.35916 1.29169 5.38833 1.29169 5.435C1.29169 5.48083 1.33669 5.51083 1.38252 5.53583Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2084 7.375C14.2084 7.03 14.4884 6.75 14.8334 6.75C15.1784 6.75 15.4584 7.03 15.4584 7.375V11.125C15.4584 12.8508 14.0592 14.25 12.3334 14.25H5.66669C3.94085 14.25 2.54169 12.8508 2.54169 11.125V7.375C2.54169 7.03 2.82169 6.75 3.16669 6.75C3.51169 6.75 3.79169 7.03 3.79169 7.375V11.125C3.79169 12.1608 4.63085 13 5.66669 13H12.3334C13.3692 13 14.2084 12.1608 14.2084 11.125V7.375Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.55837 6.14995C8.3142 5.90662 8.3142 5.50995 8.55837 5.26662C8.8017 5.02245 9.19837 5.02245 9.4417 5.26662L12.3584 8.18329C12.4759 8.29995 12.5417 8.45912 12.5417 8.62495V15.2916C12.5417 15.6366 12.2617 15.9166 11.9167 15.9166C11.5717 15.9166 11.2917 15.6366 11.2917 15.2916V8.88412L8.55837 6.14995Z"
      fill="currentcolor"
    />
  </svg>
);

export default TrainingSvg;
