import { useEffect, useState } from 'react';

export const isWidthMobileSize = (width: number) => width < Number.parseInt('992px', 10);

export const useIsMobile = (): boolean => {
  const [width, setWidth] = useState<number>(999);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return isWidthMobileSize(width);
};
