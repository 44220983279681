import React from 'react';
import styled from 'styled-components';

import ShortLogoSvg from '../../../assets/icons/ShortLogoSvg';

const ProfileDefault = ({
  radius,
  color,
  icon,
  backgroundColor,
}: {
  radius: number;
  color: string;
  backgroundColor?: string;
  icon?: React.ReactNode;
}) => {
  return (
    <ShortLogoContainer $radius={radius} $color={color} $background={backgroundColor}>
      {icon ? icon : <ShortLogoSvg />}
    </ShortLogoContainer>
  );
};

const ShortLogoContainer = styled.div<{
  $radius: number;
  $color: string;
  $background?: string;
}>`
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $radius }) => $radius}px;
  width: ${({ $radius }) => $radius}px;
  background-color: ${({ $background }) => ($background ? $background : 'transparent')};
  svg {
    height: ${({ $radius }) => $radius}px;
    width: ${({ $radius }) => $radius}px;
    color: ${({ $color }) => $color};
  }
`;

export default ProfileDefault;
