import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';

import BackendService from '../services/backendService';
import { Template } from '../services/TemplateService/Template.type';
import { presentationsCacheKey } from './usePresentations';
import useRouterMatch from './useRouterMatch';
import { templatesCacheKey } from './useTemplates';
import useWorkSpace from './useWorkSpace';
export const workspaceTemplatesCacheKey = 'workspaceTemplates';
function useDuplicateTemplate(parentId?: string) {
  const client = useQueryClient();
  const { workspaceId } = useWorkSpace();
  const cacheKey = [workspaceTemplatesCacheKey, workspaceId, parentId];
  const privatLessonsCacheKey = parentId
    ? [templatesCacheKey, parentId]
    : [templatesCacheKey];

  const canDuplicatePrompts = useFlag('AI_TEMPLATES');

  const { tenantId: activeTenantId } = useRouterMatch();

  const { mutate: duplicateTemplate, isLoading: isDuplicatingTemplate } = useMutation(
    async ({
      templateId,
      parentId,
      isPrivate,
    }: {
      templateId: string;
      parentId?: string | null;
      isPrivate?: boolean;
      onSuccessFunction?: (template: Template) => void;
    }) => {
      return await BackendService.instance.template.duplicateTemplate({
        templateId,
        parentId: parentId ? parentId : null,
        isPrivate,
        activeTenantId,
        allowGeneratorPresentation: canDuplicatePrompts || undefined,
      });
    },
    {
      onSettled: (result, error, params) => {
        client.invalidateQueries(params.isPrivate ? privatLessonsCacheKey : cacheKey);
        client.invalidateQueries([presentationsCacheKey]);
      },
      onSuccess: (data, { onSuccessFunction, isPrivate }) => {
        if (isPrivate) {
          client.setQueryData<Template[]>(privatLessonsCacheKey, prevTemplates =>
            prevTemplates ? [data.template, ...prevTemplates] : [data.template],
          );
        } else {
          client.setQueryData<Template[]>(cacheKey, prevTemplates =>
            prevTemplates ? [data.template, ...prevTemplates] : [data.template],
          );
        }
        if (onSuccessFunction) {
          onSuccessFunction(data.template);
        }
      },
    },
  );

  return {
    duplicateTemplate,
    isDuplicatingTemplate,
  };
}
export default useDuplicateTemplate;
