import { useQuery } from '@tanstack/react-query';

import backendService from '../services/backendService';

/**
 * Depending on the usage in Discovery or on private page
 * we fetch user thumbnail either from CDN (Discovery) or local cloud storage (lcoal use)
 */
export const useTemplateCreatorPhoto = ({
  isPrivate,
  creatorThumbnailId,
}: {
  isPrivate: boolean;
  creatorThumbnailId?: string | null;
}) => {
  const { data, isFetching, error } = useQuery(
    ['template-creator-photo', creatorThumbnailId],
    async () => {
      if (!creatorThumbnailId) return Promise.resolve(undefined);
      // Fetch url from cdn bucket
      if (!isPrivate && creatorThumbnailId) {
        return await backendService.instance.discover.getCreatorThumbnail(
          creatorThumbnailId,
        );
      }
      // Fetch url from cloud storage
      if (isPrivate) {
        const res = await backendService.instance.profile.getProfileImageUrl();
        return res.data;
      }
      return Promise.resolve(undefined);
    },
    {
      enabled: Boolean(creatorThumbnailId),
      retry: 1,
      staleTime: 1000 * 60 * 5,
    },
  );

  return {
    thumbnail: data,
    isLoading: isFetching,
    error,
  };
};
