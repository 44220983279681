import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';

const QUERY_LIMIT = 10;

export const resultsCacheKey = 'results';
function useGames(templateId?: string | null, limit?: number) {
  const paginatedQuery = ({ pageParam = 0 }: { pageParam?: number }) =>
    BackendService.instance.game.listResults(limit ?? QUERY_LIMIT, pageParam, templateId);

  const {
    data,
    isFetching,
    error,
    isFetchingNextPage,
    hasNextPage,

    fetchNextPage,
  } = useInfiniteQuery([resultsCacheKey, templateId], paginatedQuery, {
    getNextPageParam: lastPage => lastPage.cursor,
    staleTime: 1000 * 60 * 60,
  });

  const games = useMemo(() => data?.pages.flatMap(e => e.items), [data]);

  return {
    games: games,
    isFetchingMore: isFetchingNextPage,
    canFetchMore: hasNextPage,
    fetchMore: fetchNextPage,
    isFetching,
    error,
  };
}

export default useGames;
