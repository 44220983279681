import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useContext } from 'react';

import { TimesyncContext } from '../providers/TimesyncProvider/TimesyncProvider';
import BackendService from '../services/backendService';
import {
  Template,
  TemplateEditOrCreateDTO,
} from '../services/TemplateService/Template.type';
import { templateCacheKey } from '../utils/blueprintUtils';
import { NEW_TEMPLATE_PARAM } from '../utils/urlUtils';
import { resultsCacheKey } from './useGames';
import { useMetrics } from './useMetrics';
import { presentationsCacheKey } from './usePresentations';
import { recentTemplatesCacheKey } from './useRecentTemplates';
import useRouterMatch from './useRouterMatch';
import { templatesCacheKey } from './useTemplates';
import { workspaceTemplatesCacheKey } from './useWorkspaceTemplates';

function useAutosaveTemplate() {
  const client = useQueryClient();
  const { tenantId, router } = useRouterMatch();
  const metrics = useMetrics();
  const { now } = useContext(TimesyncContext);

  const {
    mutate: autoSaveTemplate,
    isLoading: isSavingTemplate,
    isError: isSavingTemplateError,
    isSuccess: isSavingTemplateSuccess,
    isIdle: isSavingTemplateIdle,
  } = useMutation<
    Template | undefined,
    AxiosError,
    {
      templateConfig?: TemplateEditOrCreateDTO | null;
      onSuccessFunction?: (data: Template) => void | Promise<void> | undefined;
    }
  >(
    config => {
      if (!config.templateConfig) return Promise.resolve(undefined);
      //updated the modifiedAt time using the offset correction
      const timeSyncedTemplateConfig = {
        ...config.templateConfig,
        modifiedAt: now(),
      };

      return BackendService.instance.template.templateAutoSave(
        timeSyncedTemplateConfig,
        tenantId,
      );
    },
    {
      retryDelay: 2000,
      onSuccess: (data, { onSuccessFunction, templateConfig }) => {
        if (!data) return;

        if (templateConfig && templateConfig?.id === NEW_TEMPLATE_PARAM && data.id) {
          metrics.logEvent('Template.New');
          // Make sure we only set the cache and redirect when we save a new template without an ID
          client.setQueryData<Template | undefined>(
            [templateCacheKey, data.id],
            (exitingsTemplate: Template | undefined) => {
              if (!exitingsTemplate) return data;
              if (exitingsTemplate.modifiedAt < data.modifiedAt) return data;
              return { ...exitingsTemplate, id: data.id };
            },
          );

          // Clear the old [templateId, "new"] react query cache
          // templateConfig.id is "new" at this line
          client.setQueryData([templateCacheKey, templateConfig.id], () => undefined);

          router.replace(`/${tenantId}/lessons/${data.id}/edit`);
          /* After this happens - useTemplate in the creator will return the latest cache, 
            and we will sync template into localTemplate to prevent infinite saves
          */
        }
        if (templateConfig?.parentId) {
          // invalidate folder presentation cache if this lesson was created there.
          client.invalidateQueries([
            presentationsCacheKey,
            templateConfig.isPrivate,
            templateConfig.parentId,
          ]);
        }
        client.invalidateQueries([templatesCacheKey]);
        client.invalidateQueries([recentTemplatesCacheKey]);
        client.invalidateQueries([templateConfig?.id, 'presentationVersion']);
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
      retry: (count: number, error) => {
        // due to SSR fetching we dont want to retry on 403s and 404s
        if (error.response?.status && [403, 404].includes(error.response?.status)) {
          return false;
        }

        // Default retry is 3 times
        if (count > 3) {
          return false;
        }
        return true;
      },
      onMutate: async mutateBody => {
        const templateCacheKey = ['template', mutateBody.templateConfig?.id];

        await client.cancelQueries(templateCacheKey);
        const previousTemplate = client.getQueryData<Template>(templateCacheKey);
        client.setQueryData<Template | undefined>(templateCacheKey, template => {
          if (!template || !mutateBody.templateConfig) return template;
          return {
            ...template,
            title:
              mutateBody.templateConfig.title === undefined
                ? template.title
                : mutateBody.templateConfig.title,
            currentRound:
              mutateBody.templateConfig.currentRound === undefined
                ? template.currentRound
                : mutateBody.templateConfig.currentRound,
            description:
              mutateBody.templateConfig.description === undefined ||
              mutateBody.templateConfig.description === null
                ? template.description
                : mutateBody.templateConfig.description,
            coverImageAltText:
              mutateBody.templateConfig.coverImageAltText === undefined ||
              mutateBody.templateConfig.coverImageAltText === null
                ? template.coverImageAltText
                : mutateBody.templateConfig.coverImageAltText,
            coverImageId:
              mutateBody.templateConfig.coverImageId === undefined
                ? template.coverImageId
                : mutateBody.templateConfig.coverImageId,
            language:
              mutateBody.templateConfig.language === undefined
                ? template.language
                : mutateBody.templateConfig.language,
            requireRealNames:
              mutateBody.templateConfig.requireRealNames === undefined
                ? template.requireRealNames
                : mutateBody.templateConfig.requireRealNames,
            modifiedAt:
              mutateBody.templateConfig.modifiedAt === undefined
                ? template.modifiedAt
                : mutateBody.templateConfig.modifiedAt,
            isPrivate:
              mutateBody.templateConfig.isPrivate === undefined ||
              mutateBody.templateConfig.isPrivate === null
                ? template.isPrivate
                : mutateBody.templateConfig.isPrivate,
            parentId:
              mutateBody.templateConfig.parentId === undefined
                ? template.parentId
                : mutateBody.templateConfig.parentId,
            templateSharingPublic:
              mutateBody.templateConfig.templateSharingPublic === undefined ||
              mutateBody.templateConfig.templateSharingPublic === null
                ? template.templateSharingPublic
                : mutateBody.templateConfig.templateSharingPublic,

            isBlockedFromSharing:
              mutateBody.templateConfig.isBlockedFromSharing === undefined ||
              mutateBody.templateConfig.isBlockedFromSharing === null
                ? template.isBlockedFromSharing
                : mutateBody.templateConfig.isBlockedFromSharing,
            content: {
              ...template.content,
              activeGame:
                mutateBody.templateConfig.activeGame === undefined
                  ? template.content.activeGame
                  : mutateBody.templateConfig.activeGame,
              gameIds:
                mutateBody.templateConfig.gameIds === undefined
                  ? template.content.gameIds
                  : mutateBody.templateConfig.gameIds,
              slides:
                mutateBody.templateConfig.slides === undefined
                  ? template.content.slides
                  : mutateBody.templateConfig.slides,
            },
            aiContent:
              mutateBody.templateConfig.aiContent === undefined
                ? template.aiContent
                : mutateBody.templateConfig.aiContent || undefined,
          };
        });

        // updates the title in the templates list query
        if (mutateBody.templateConfig?.title && previousTemplate) {
          // title for my lesson listing
          if (previousTemplate?.isPrivate) {
            const cacheKey = previousTemplate.parentId
              ? [templatesCacheKey, previousTemplate.parentId]
              : [templatesCacheKey];
            client.invalidateQueries(cacheKey);
            client.invalidateQueries([presentationsCacheKey, true]);
            client.invalidateQueries([recentTemplatesCacheKey, true]);
          }

          // title for workspace listing
          if (!previousTemplate?.isPrivate) {
            const cacheKey = previousTemplate.parentId
              ? [workspaceTemplatesCacheKey, previousTemplate.parentId]
              : [workspaceTemplatesCacheKey];
            client.invalidateQueries(cacheKey);
            //Invalidate the new workspace cache query
            client.invalidateQueries([presentationsCacheKey, false]);
            client.invalidateQueries([recentTemplatesCacheKey, false]);
          }
        }
        // invalidate the query for listing games related to the template
        if (mutateBody.templateConfig?.gameIds) {
          const cacheKey = [resultsCacheKey, mutateBody.templateConfig.id];
          client.invalidateQueries(cacheKey);
        }

        // Return the snapshotted value
        return () => client.setQueryData(templateCacheKey, previousTemplate);
      },
    },
  );

  return {
    autoSaveTemplate,
    isSavingTemplate,
    isSavingTemplateError,
    isSavingTemplateSuccess,
    isSavingTemplateIdle,
  };
}
export default useAutosaveTemplate;
