import { Folder } from '../services/TemplateService/Template.type';

export const blueprintsCachePath = 'blueprints';
export const templateCacheKey = 'template';

export type FolderBreadCrumb = {
  id: string;
  title: string;
  parentId?: string | null;
  children?: FolderBreadCrumb[];
};

const getParentFolderCrumb = (
  parentFolderId: string,
  folders: Folder[],
): FolderBreadCrumb => {
  const parentFolder = folders.find(folder => folder.id === parentFolderId) as Folder;
  return {
    id: parentFolder.id,
    title: parentFolder.title as string,
    parentId: parentFolder.parentId,
  };
};

export const getExpandedTreeNodes = (
  folders: Folder[],
  parentFolderId?: string | null,
  workspaceId?: string,
) => {
  const breadCrumbs: FolderBreadCrumb[] = [];

  if (parentFolderId && parentFolderId !== workspaceId) {
    const activeFolder = folders.find(folder => folder.id === parentFolderId) as Folder;
    breadCrumbs.push({
      id: activeFolder.id,
      title: activeFolder?.title ? activeFolder?.title : 'Untitled',
      parentId: activeFolder?.parentId ? activeFolder?.parentId : undefined,
    });

    while (
      breadCrumbs[breadCrumbs.length - 1].parentId &&
      breadCrumbs[breadCrumbs.length - 1].id !== workspaceId
    ) {
      const newBreadCrumb = getParentFolderCrumb(
        breadCrumbs[breadCrumbs.length - 1].parentId as string,
        folders,
      );
      breadCrumbs.push(newBreadCrumb);
    }
  }
  return breadCrumbs.reverse().map(b => b.id);
};
