import { useQuery } from '@tanstack/react-query';

import backendService from '../services/backendService';

/**
 * As we pass the fileId we allow the backend to set maximum cache.
 * Meaning the browser automatically will cache these images on disk
 * If a new thumbnail is added to the cover photo, it will get a new fileId. hence we can cache the hell out of this one.
 */
export const useTemplateCoverPhoto = (templateId: string, fileId?: string | null) => {
  const { data, isFetching, error } = useQuery<string | undefined, string>(
    ['template-cover-photo', templateId, fileId],
    () => {
      if (!fileId) return Promise.resolve(undefined);

      return backendService.instance.discover.getTemplateThumbnail(templateId, fileId);
    },
    {
      enabled: Boolean(fileId),
      staleTime: 1000 * 60 * 5,
    },
  );

  return {
    thumbnail: data,
    isLoading: isFetching,
    error,
  };
};
