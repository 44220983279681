import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import { Template } from '../services/TemplateService/Template.type';
import { convertPresentationDTOToTemplateDataType } from '../utils/templateUtils';
export const presentationsSearchCacheKey = 'presentations-search';

export const currentPresentationsSearchCacheKey = ({
  parentFolderId,
  isPrivate,
  search,
}: {
  parentFolderId?: string;
  isPrivate: boolean;
  search: string;
}) => [presentationsSearchCacheKey, isPrivate, parentFolderId, search];

function usePresentationsSearch({
  parentFolderId,
  isPrivate,
  search,
}: {
  parentFolderId?: string;
  isPrivate: boolean;
  search: string;
}) {
  const QUERY_LIMIT = 10;

  const cacheKey = currentPresentationsSearchCacheKey({
    isPrivate,
    parentFolderId,
    search,
  });

  const client = useQueryClient();

  const {
    data: presentations,
    isFetching,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    cacheKey,
    ({ pageParam = undefined }: { pageParam?: number }) =>
      BackendService.instance.presentations.search({
        search,
        isPrivate,
        parentFolderId,
        cursor: pageParam,
        limit: QUERY_LIMIT,
      }),
    {
      getNextPageParam: lastPage => lastPage.cursor,
      staleTime: 1000 * 60 * 1,
      onSuccess: data => {
        data.pages.forEach(page => {
          page.items.forEach(presentation => {
            const template: Template = convertPresentationDTOToTemplateDataType(
              presentation,
            );
            client.setQueryData(['template', template.id], template);
          });
        });
      },
    },
  );

  const allPresentations = useMemo(
    () => presentations?.pages?.flatMap(page => page.items) || undefined,
    [presentations?.pages],
  );

  return {
    presentations: allPresentations,
    isFetching,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    total: presentations?.pages?.[0]?.total,
  };
}
export default usePresentationsSearch;
