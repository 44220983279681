import { signOut } from 'next-auth/react';
import { useCallback } from 'react';

import backendService from '../services/backendService';

function useSignOut() {
  const b2cSignout = useCallback(async (redirectUrl?: string) => {
    const url = await backendService.instance.getSignOutUrl();
    if (url) {
      await signOut({
        callbackUrl: `${url}?post_logout_redirect_uri=${redirectUrl ||
          window.location.origin}`,
      });
    }
  }, []);

  return {
    b2cSignout,
  };
}

export default useSignOut;
