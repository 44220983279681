import { useSession } from 'next-auth/react';
import { ReactNode } from 'react';

import useSignIn from '../../hooks/useSignIn';

const AuthGuardProvider = ({
  allowUnAuthenticated,
  children,
}: {
  children: ReactNode;
  allowUnAuthenticated?: boolean;
}) => {
  const { signIn } = useSignIn();
  const session = useSession();
  if (allowUnAuthenticated) return <>{children}</>;
  if (session.status === 'loading') return <>{children}</>;
  if (session.status === 'authenticated') return <>{children}</>;
  if (session.status === 'unauthenticated') {
    signIn();
  }
  return <></>;
};

export default AuthGuardProvider;
