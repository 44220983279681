const Linkedin = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8097 27.463V20.1755C29.8097 16.2711 27.7253 14.4542 24.9465 14.4542C22.704 14.4542 21.6991 15.6881 21.1391 16.5536V14.7529H16.9142C16.9702 15.9457 16.9142 27.463 16.9142 27.463H21.1391V20.3647C21.1391 19.9858 21.1665 19.6062 21.2785 19.3349C21.5833 18.5758 22.2784 17.7899 23.445 17.7899C24.9745 17.7899 25.5855 18.9554 25.5855 20.6634V27.4636H29.8097V27.463ZM12.4634 13.0181C13.9362 13.0181 14.854 12.0412 14.854 10.8211C14.8266 9.57475 13.9362 8.62586 12.4914 8.62586C11.0466 8.62586 10.1015 9.57412 10.1015 10.8211C10.1015 12.0412 11.0186 13.0181 12.4367 13.0181H12.4634ZM19.3334 37.3333C9.02443 37.3333 0.666748 28.9756 0.666748 18.6666C0.666748 8.35706 9.02443 0 19.3334 0C29.6424 0 38 8.35706 38 18.6666C38 28.9756 29.6424 37.3333 19.3334 37.3333ZM14.5759 27.463V14.7529H10.3516V27.463H14.5759Z"
      fill="currentColor"
    />
  </svg>
);

export default Linkedin;
