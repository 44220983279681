const SignSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6549_60250)">
      <path
        d="M19.9228 3.45351L18.2562 1.45355C18.1928 1.37747 18.0989 1.33351 17.9998 1.33355H11.3333V0.333571C11.3333 0.149491 11.1841 0.000244141 11 0.000244141H9.00003C8.81595 0.000244141 8.66671 0.149491 8.66671 0.333571V1.33355H7.00007C6.81599 1.33355 6.66675 1.4828 6.66675 1.66688V5.66679C6.66675 5.85087 6.81599 6.00012 7.00007 6.00012H8.66671V6.66677H2.00018C1.90114 6.66673 1.80722 6.71069 1.74385 6.78677L0.0772171 8.78673C-0.025739 8.91031 -0.025739 9.0898 0.0772171 9.21338L1.74385 11.2133C1.80722 11.2894 1.90114 11.3334 2.00018 11.3333H8.66671V19.6665C8.66671 19.8506 8.81595 19.9998 9.00003 19.9998H11C11.1841 19.9998 11.3333 19.8506 11.3333 19.6665V11.3333H12.9999C13.184 11.3333 13.3333 11.1841 13.3333 11V7.0001C13.3333 6.81602 13.184 6.66677 12.9999 6.66677H11.3333V6.00012H17.9998C18.0989 6.00016 18.1928 5.9562 18.2562 5.88012L19.9228 3.88016C20.0258 3.75658 20.0258 3.57709 19.9228 3.45351ZM9.33336 0.666897H10.6667V1.33355H9.33336V0.666897ZM10.6667 19.3332H9.33336V11.3333H10.6667V19.3332ZM12.6666 7.33342V10.6667H2.15651L0.766869 9.00006L2.15651 7.33342H12.6666ZM9.33336 6.66677V6.00012H10.6667V6.66677H9.33336ZM17.8442 5.33347H7.3334V2.0002H17.8435L19.2332 3.66683L17.8442 5.33347Z"
        fill="black"
      />
    </g>
  </svg>
);

export default SignSvg;
