const BookmarkSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 1.66666H6.66663C6.00359 1.66666 5.36771 1.93006 4.89887 2.3989C4.43002 2.86774 4.16663 3.50362 4.16663 4.16666V17.5C4.16605 17.6468 4.20428 17.7912 4.27745 17.9185C4.35062 18.0459 4.45613 18.1516 4.5833 18.225C4.70998 18.2981 4.85369 18.3366 4.99997 18.3366C5.14625 18.3366 5.28995 18.2981 5.41663 18.225L9.99997 15.575L14.5833 18.225C14.7103 18.297 14.854 18.3343 15 18.3333C15.1459 18.3343 15.2896 18.297 15.4166 18.225C15.5438 18.1516 15.6493 18.0459 15.7225 17.9185C15.7957 17.7912 15.8339 17.6468 15.8333 17.5V4.16666C15.8333 3.50362 15.5699 2.86774 15.1011 2.3989C14.6322 1.93006 13.9963 1.66666 13.3333 1.66666ZM14.1666 16.0583L10.4166 13.8917C10.2899 13.8185 10.1462 13.78 9.99997 13.78C9.85368 13.78 9.70998 13.8185 9.5833 13.8917L5.8333 16.0583V4.16666C5.8333 3.94565 5.9211 3.73369 6.07738 3.57741C6.23366 3.42113 6.44562 3.33333 6.66663 3.33333H13.3333C13.5543 3.33333 13.7663 3.42113 13.9226 3.57741C14.0788 3.73369 14.1666 3.94565 14.1666 4.16666V16.0583Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default BookmarkSvg;
