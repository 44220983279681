import React from 'react';

export const UsageGraphSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0C1.55228 0 2 0.447715 2 1V16H17.5C18.0523 16 18.5 16.4477 18.5 17C18.5 17.5523 18.0523 18 17.5 18H1C0.447715 18 0 17.5523 0 17V1C0 0.447715 0.447715 0 1 0ZM14 4.5C14 3.94772 14.4477 3.5 15 3.5H17.5C17.7992 3.5 18.0827 3.63396 18.2726 3.86512C18.4626 4.09628 18.539 4.40035 18.481 4.69387L18.0513 6.86847C17.9442 7.41028 17.4182 7.7627 16.8764 7.65564C16.4992 7.5811 16.2138 7.30353 16.1113 6.95894L12.1402 10.2682C11.7693 10.5773 11.2307 10.5773 10.8598 10.2682L8.52105 8.31925L4.66436 11.7474C4.25158 12.1143 3.61951 12.0771 3.25259 11.6644C2.88567 11.2516 2.92285 10.6195 3.33564 10.2526L7.83564 6.25259C8.20545 5.92387 8.76007 5.91502 9.14018 6.23178L11.5 8.19829L14.7699 5.47339C14.3285 5.36945 14 4.97309 14 4.5Z"
      fill="currentColor"
    />
  </svg>
);

export default UsageGraphSvg;
