import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import backendService from '../services/backendService';
import {
  EmailInvitationCreateDTO,
  InvitationRole,
  TopicInvitation,
} from '../services/backendService/types';
import { useMetrics } from './useMetrics';
function useInvitation(role: InvitationRole, topic?: TopicInvitation, duration?: number) {
  const metrics = useMetrics();
  const client = useQueryClient();
  const { data, isFetching } = useQuery(
    [`invitation`, role, topic, duration],
    () => backendService.instance.invitations.generate(role, topic, duration),
    {
      staleTime: 1000 * 60 * 50,
    },
  );

  const { mutate: bulkInvite, error, isSuccess, isLoading } = useMutation(
    (val: EmailInvitationCreateDTO[]) => {
      metrics.logEvent('Invite.Email', {
        count: val.length,
        role: val[0] && val[0].role,
        topicId: val[0] && val[0].topic?.topicId,
      });
      return backendService.instance.invitations.bulkInvite(val);
    },
    {
      onSettled: () => {
        client.invalidateQueries(['pending-invitations']);
      },
    },
  );

  return {
    url:
      data &&
      `${window.location.origin}/join/${data.code}?utm_medium=link&utm_source=curipod&utm_content=curipod_invitation`,
    isFetching: isFetching,
    bulkInvite,
    error,
    isSuccess,
    isLoading,
  };
}

export default useInvitation;
