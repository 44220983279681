import { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { merge, of, Subscription, timer } from 'rxjs';
import { concatMap, mergeMap, take } from 'rxjs/operators';
import { Socket } from 'socket.io-client';

import GameAPI from '../frontendServices/gameAPI';
import { NewRelic, newRelicNoticeError } from './useNewRelic';
const MIN_5_MS = 5 * 60 * 1000;
const TIMESYNCDATA_CACHE_KEY = '@curipod_v1_timesyncdata';
type TimeSyncData = { timestamp: number; offsets: number[] };

class CustomTimesync {
  private pingIntervalMS: number = 1000 * 1;
  private resyncIntervalMS: number = 1000 * 15;
  private numRequests = 5;
  private offsetMeanMS = 0;
  private timesyncPipe: Subscription | undefined = undefined;
  private timeEntries: { [id: string]: number };
  private offsets: number[];
  private latencies: number[];
  private latencyMeanMS = 0;
  private socket: Socket;
  constructor(private gameAPI: GameAPI, private gameId?: string) {
    this.socket = gameAPI.getSocket();
    this.offsets = [];
    this.latencies = [];
    try {
      const timeSyncDataString = sessionStorage.getItem(TIMESYNCDATA_CACHE_KEY);
      const timeSyncData = JSON.parse(timeSyncDataString || '{}') as TimeSyncData;
      if (
        timeSyncData?.timestamp &&
        new Date().getTime() - timeSyncData.timestamp < MIN_5_MS
      ) {
        this.offsets = Array.isArray(timeSyncData?.offsets) ? timeSyncData.offsets : [];
      }
    } catch (error) {
      newRelicNoticeError(
        error,
        'Error while reading timesync data from session storage',
      );
    }
    this.timeEntries = {};
    this.socket.on('timesync:pong', (data: { id: string; serverTime: number }) => {
      const sentTime = this.timeEntries[data.id];
      const received = new Date().getTime();
      const transportTime = (received - sentTime) / 2;
      const localOffset = data.serverTime - sentTime - transportTime;
      if (this.offsets.length >= 10) {
        this.offsets.splice(0, 1); // Remove the first element
      }

      if (this.latencies.length >= 10) {
        this.latencies.splice(0, 1); // Remove the first element
      }

      this.offsets.push(localOffset);
      this.latencies.push(transportTime);
      try {
        const newTimeSyncData: TimeSyncData = {
          timestamp: new Date().getTime(),
          offsets: this.offsets,
        };
        sessionStorage.setItem(TIMESYNCDATA_CACHE_KEY, JSON.stringify(newTimeSyncData));
      } catch (error) {
        newRelicNoticeError(error, 'Error while saving timesync data to session storage');
      }
      this.offsetMeanMS =
        this.offsets.reduce((acc, cur) => acc + cur, 0) / this.offsets.length;

      this.latencyMeanMS =
        this.latencies.reduce((acc, cur) => acc + cur, 0) / this.latencies.length;

      this.gameAPI.emitLatency(Number(this.latencyMeanMS.toFixed(0)));
      NewRelic()?.addPageAction('SocketLatency', {
        latency: Number(this.latencyMeanMS.toFixed(0)),
        gameId: this.gameId || '',
      });
    });
  }

  public start() {
    this.timesyncPipe = merge(
      timer(0, this.resyncIntervalMS),
      this.gameAPI.ensureConnected(),
    ) // start immediately and emit again every 15 seconds and restart every time we reconnect with the server
      .pipe(
        mergeMap(() =>
          // start immediately and emit again every delay
          timer(0, this.pingIntervalMS).pipe(
            take(this.numRequests),
            concatMap(() => {
              const time = new Date().getTime();
              const id = time.toString();
              this.timeEntries[id] = time;
              const s = this.socket.volatile.emit('timesync:ping', { id });
              return of(s);
            }),
          ),
        ),
      )
      .subscribe(() => {
        // Empty
      });
  }

  public now() {
    return new Date().getTime() + this.offsetMeanMS;
  }

  public destroy() {
    this.socket.off('timesync:pong');
    if (this.timesyncPipe) {
      this.timesyncPipe.unsubscribe();
    }
  }
}

function useTimesync(gameAPI: GameAPI, gameId?: string) {
  const ref = useRef<CustomTimesync>();

  useEffect(() => {
    ref.current = new CustomTimesync(gameAPI, gameId);
    ref.current.start();
    return () => {
      ref.current?.destroy();
    };
  }, [gameAPI, gameId]);

  const now = useCallback(() => {
    if (ref.current) {
      return new Date(ref.current.now()).getTime();
    }
    return new Date().getTime();
  }, []);

  return {
    now,
  };
}

export default useTimesync;
