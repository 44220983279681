import { Divider, Select, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CrossSvg, QuestionMarkSvg } from '../../../assets/icons';
import LinkSvg from '../../../assets/icons/LinkSvg';
import theme from '../../../assets/theme';
import useInvitation from '../../../hooks/useInvitation';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import useMe from '../../../hooks/useMe';
import { newRelicNoticeError } from '../../../hooks/useNewRelic';
import useTenant from '../../../hooks/useTenant';
import {
  EmailInvitationCreateDTO,
  TopicInvitation,
} from '../../../services/backendService/types';
import { Loader } from '../../_atoms/_Loader';
import { Button } from '../../_atoms/Button';
import Label from '../../_atoms/Label';
import { CenteredContainer } from '../../styled/CenteredContainer';
import { InvitationButton } from '../InvitationLink/InvitationLink';

const { Option } = Select;

const helpfulEmailRegex = new RegExp(
  `[a-zA-Z0-9.!#$%&amp;^_{}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*`,
);

const containsInvalidEmail = (emails: string[]) =>
  emails.some(email => !helpfulEmailRegex.test(email));

const InvitationLinkSettings = ({
  onDone,
  duration,
  role,
}: {
  duration?: number;
  role: 'student' | 'mentor';
  onDone: (invitationDuration: number, role: 'student' | 'mentor') => void;
}) => {
  const [invitationDuration, setInvitationDuration] = useState(14 * 24);

  const [localRole, setRole] = useState(role);

  useEffect(() => {
    if (duration !== undefined) {
      setInvitationDuration(duration);
    }
  }, [duration]);
  useEffect(() => {
    if (role !== undefined) {
      setRole(role);
    }
  }, [role]);

  return (
    <>
      <h1>Invitation link settings</h1>
      <div style={{ width: '100%' }}>
        <p style={{ fontWeight: 'bold', color: theme.colors.black }}>Role</p>
        <ModalSelect
          style={{ width: '100%' }}
          onChange={e => setRole(e as 'student' | 'mentor')}
          value={localRole}
        >
          <Option value={'mentor'}>Teacher</Option>
          <Option value={'student'}>Student</Option>
        </ModalSelect>
        <p style={{ fontWeight: 'bold', color: theme.colors.black, marginTop: 20 }}>
          Expires in
        </p>
        <ModalSelect
          style={{ width: '100%' }}
          onChange={e => setInvitationDuration(e as number)}
          value={invitationDuration}
        >
          <Option value={2}>2 hours</Option>
          <Option value={12}>12 hours</Option>
          <Option value={7 * 24}>7 days</Option>
          <Option value={14 * 24}>14 days</Option>
          <Option value={-1}>Never</Option>
        </ModalSelect>

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button onClick={() => onDone(invitationDuration, localRole)}>
            Generate new link
          </Button>
        </div>
      </div>
    </>
  );
};

export const ModalSelect = styled(Select)`
  && .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    color: ${theme.colors.black};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 10px;
    mix-blend-mode: normal;
  }

  && .ant-select-selection-search {
    margin-inline-start: 0px !important;
  }
  && .ant-select-selection-placeholder {
    color: #a5a5a5;
  }
`;

function sanitizeEmail(email: string) {
  // When pasting from windows, a newline might look like: \r\n instead of \n. Antd doesnt handle this, and thus we need to handle it
  return email.replace('\r', '');
}

const InvitationModal = ({
  visible,
  onDone,
  role,
  topic,
  isSignup,
  title,
}: {
  visible: boolean;
  onDone: () => void;
  role: 'student' | 'mentor';
  topic?: TopicInvitation;
  isSignup?: boolean;
  title?: string;
}) => {
  const { t } = useTranslation('Host');
  return (
    <ModalStyled
      visible={visible}
      centered
      destroyOnClose
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
      onCancel={() => onDone()}
      onOk={() => onDone()}
    >
      <ModalContainer>
        <CrossIconWrapper onClick={() => onDone()}>
          <CrossSvg />
        </CrossIconWrapper>
        <h1 style={{ width: '100%', textAlign: 'left' }}>{title || t('Invite')}</h1>
        <InviteToTenant
          onDone={onDone}
          role={role}
          topic={topic}
          isSignup={isSignup}
          hideValidity={false}
        />
      </ModalContainer>
    </ModalStyled>
  );
};

export const InviteToTenant = ({
  onDone,
  role,
  topic,
  isSignup,
  hideValidity = false,
  showEmailInvite = true,
}: {
  onDone: () => void;
  role: 'student' | 'mentor';
  topic?: TopicInvitation;
  isSignup?: boolean;
  hideValidity?: boolean;
  style?: CSSProperties;
  showEmailInvite?: boolean;
}) => {
  const { me, isLoading: isLoadingMe } = useMe();
  const { data: tenant, isFetching: isFetchingTenant } = useTenant();
  const isDesktop = useIsDesktop();
  const [val, setVal] = useState<string[]>([]);
  const { t } = useTranslation('Host');
  const [invitationDuration, setInvitationDuration] = useState(14 * 24);
  const [isEditingInvitation, setIsEditingDuration] = useState(false);
  const [localRole, setLocalRole] = useState(role);
  useEffect(() => {
    setLocalRole(role);
  }, [role]);

  const canInvite = !!(
    (tenant && !tenant.isCuripodPremium) ||
    (tenant &&
      tenant.isCuripodPremium &&
      (me?.role === 'admin' || me?.role === 'superAdmin'))
  );

  const isPremium = tenant && tenant.isCuripodPremium;

  const isLoadingCanInvite = isLoadingMe || isFetchingTenant;

  const {
    url,
    bulkInvite,
    error: inviteError,
    isLoading,
    isSuccess,
    isFetching,
  } = useInvitation(localRole, topic, invitationDuration);
  const handleSend = () => {
    if (val.length === 0) {
      setError(t('Please enter one or more email addresses to invite new users'));
      return;
    }
    if (containsInvalidEmail(val)) {
      setError(t('Atleast one of the provided emails are not valid'));
      return;
    }
    const payloads = val.map(email => {
      const p: EmailInvitationCreateDTO = {
        email: sanitizeEmail(email),
        role: localRole,
        topic,
      };
      return p;
    });
    bulkInvite(payloads);
  };

  useEffect(() => {
    if (inviteError) {
      newRelicNoticeError(inviteError, 'Error while sending invitation');

      setError(t('An error occurred, please try again'));
    }
  }, [inviteError, t]);

  useEffect(() => {
    if (isSuccess) {
      onDone();
    }
  }, [isSuccess, onDone]);

  const handleChange = (e: string[]) => {
    const hey = new Set([...e]);
    const yo = Array.from(hey);
    setVal(yo);
  };
  const CustomTagRenderer = (props: {
    value: string;
    closable: boolean;
    onClose: () => void;
  }) => {
    const { value, closable, onClose } = props;

    const isValidEmail = helpfulEmailRegex.test(value);
    return (
      <Label
        backgroundColor={isValidEmail ? theme.colors.green : theme.colors.red}
        color={theme.colors.white}
        text={value}
        closable={closable}
        onClose={onClose}
        style={{
          fontSize: 13,
          fontWeight: 'bold',
          marginRight: 5,
          marginTop: 2,
          marginBottom: 2,
        }}
      />
    );
  };

  const [error, setError] = useState<string>();
  if (isLoadingCanInvite) {
    return (
      <div style={{ width: '100%' }}>
        <CenteredContainer>
          <Loader />
        </CenteredContainer>
      </div>
    );
  }

  if (!canInvite) {
    return (
      <div style={{ width: '100%' }}>
        <CenteredContainer>
          <p>{t('Only the admin of this tenant can invite other members')}</p>
        </CenteredContainer>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {isPremium && (
        <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
          {t('Note: A new team member counts as one seat for your next billing cycle')}
        </p>
      )}
      {isEditingInvitation && (
        <InvitationLinkSettings
          role={localRole}
          onDone={(duration, role) => {
            setInvitationDuration(duration);
            setLocalRole(role);
            setIsEditingDuration(false);
          }}
        />
      )}
      {!isEditingInvitation && (
        <>
          {showEmailInvite && (
            <div style={{ width: '100%' }}>
              <p style={{ fontSize: 16, fontWeight: 'bold' }}>{t('Emails')}</p>
              <SelectStyled
                mode="tags"
                placeholder={t(
                  'youCanCopy',
                  'You can copy and paste a list of emails...',
                )}
                tokenSeparators={[',', ' ', '\r\n', '\n', '\t']}
                style={{ width: '100%', maxHeight: '250px', overflowY: 'auto' }}
                value={val}
                onChange={e => handleChange(e as string[])}
                tagRender={({ closable, onClose, value }) => (
                  <CustomTagRenderer
                    closable={closable}
                    onClose={onClose}
                    value={value as string}
                  />
                )}
                dropdownRender={() => <></>}
                dropdownStyle={{ display: 'none' }}
              />
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                {t(
                  'weWillSendAn',
                  'We will send an email invitation with a link to each of the emails above.',
                )}
              </p>
              <HorizontalWrapper
                style={{ justifyContent: 'space-between', marginTop: 10 }}
              >
                <Button type="primary" onClick={handleSend} loading={isLoading}>
                  {isDesktop ? t('Send invitations') : t('Send')}
                </Button>
                {error && (
                  <p
                    style={{
                      flex: 1,
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: theme.colors.red,
                      padding: '0px 30px',
                      marginBottom: 0,
                    }}
                  >
                    {error}
                  </p>
                )}
              </HorizontalWrapper>
            </div>
          )}
          {showEmailInvite && (
            <Divider style={{ borderTop: `2px solid #d9d9d9` }} type="horizontal" />
          )}
          {localRole && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <HorizontalWrapper>
                <IconWrapper $height={22} $width={22}>
                  <LinkSvg />
                </IconWrapper>
                <ResponsiveInviteLinkText>{t('Invite link')}</ResponsiveInviteLinkText>
                <Tooltip
                  title={t('People with this link can join this tenant')}
                  trigger={isDesktop ? 'hover' : 'click'}
                  zIndex={99999}
                >
                  <IconWrapper $height={16} $width={16}>
                    <QuestionMarkSvg />
                  </IconWrapper>
                </Tooltip>
              </HorizontalWrapper>

              <InvitationButton isFetching={isFetching} isSignup={isSignup} url={url} />
            </div>
          )}
          {!hideValidity && (
            <ValidText>
              {invitationDuration === -1 && t('You invitation link will never expire.')}
              {invitationDuration !== -1 &&
                `${'Your invitation link expires in'} ${
                  invitationDuration > 24
                    ? `${invitationDuration / 24} ${t('days')}`
                    : `${invitationDuration} ${t('hours')}`
                }. `}
              <HoverableTextButton onClick={() => setIsEditingDuration(true)}>
                {t('Edit invitation link')}
              </HoverableTextButton>
            </ValidText>
          )}
        </>
      )}
    </div>
  );
};

const ResponsiveInviteLinkText = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap;
  flex: 0;
  margin-right: 3px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const HoverableTextButton = styled.span`
  color: ${theme.colors.green};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    text-decoration: underline;
  }
`;

const CrossIconWrapper = styled.div`
  color: ${theme.colors.black};
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  top: 20px;
  right: 20px;
  position: absolute;
`;

const IconWrapper = styled.div<{ $height: number; $width: number }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.black};
  svg {
    height: ${({ $height }) => `${$height}px`};
    width: ${({ $width }) => `${$width}px`};
  }
`;

const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ValidText = styled.p`
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
  color: ${theme.colors.text.darkGrey};
  font-weight: bold;
`;

const ModalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  h1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: none;
  }
  .ant-modal-body {
    box-shadow: none;
    border-radius: 10px;
    background-color: ${theme.colors.background};
  }
`;

export const SelectStyled = styled(Select)`
  && .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    color: ${theme.colors.black};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    padding: 10px 10px;
    mix-blend-mode: normal;
    min-height: 60px;
  }

  && .ant-select-selection-search {
    margin-inline-start: 0px !important;
  }
  && .ant-select-selection-placeholder {
    color: #a5a5a5;
  }
  && .ant-select-selection-item {
    border-radius: 20px;
    background-color: ${theme.colors.green};
    color: white;
    padding: 0 10px;
    // height: 35px;
    display: flex;
    align-items: center;
  }
  && .ant-select-selection-item-remove {
    color: white;
  }
`;

export default InvitationModal;
