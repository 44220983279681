const Facebook = () => (
  <svg
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5659 28.8213H20.9021V18.6654H23.7948L24.178 15.166H20.9021L20.9066 13.4139C20.9066 12.5017 20.9966 12.012 22.3494 12.012H24.1574V8.51199H21.264C17.7888 8.51199 16.5659 10.21 16.5659 13.0648V15.166H14.3998V18.666H16.5659V28.8213ZM19.2889 37.3333C8.63627 37.3333 0 28.9756 0 18.6666C0 8.35706 8.63627 0 19.2889 0C29.9415 0 38.5777 8.35706 38.5777 18.6666C38.5777 28.9756 29.9415 37.3333 19.2889 37.3333Z"
      fill="currentColor"
    />
  </svg>
);

export default Facebook;
