import React, { createContext, useMemo } from 'react';

import GameAPI from '../../frontendServices/gameAPI';
import useTimesync from '../../hooks/useTimesync';

export const TimesyncContext = createContext<{ now: () => number }>({
  now: () => new Date().getTime(),
});

const TimesyncProvider = ({
  children,
  gameAPI,
  gameId,
}: {
  gameAPI: GameAPI;
  children: React.ReactNode;
  gameId?: string;
}) => {
  const { now } = useTimesync(gameAPI, gameId);

  const value = useMemo(() => ({ now }), [now]);

  return <TimesyncContext.Provider value={value}>{children}</TimesyncContext.Provider>;
};

export default TimesyncProvider;
