const StackSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6807_59173)">
      <path
        d="M19.4917 5.33606L10.3479 1.25845C10.1266 1.15897 9.87356 1.15897 9.65103 1.25845L0.507761 5.33606C0.209304 5.46894 0.0122105 5.76145 0.00114221 6.08838C-0.00953775 6.41467 0.16516 6.71953 0.453778 6.87307L9.59692 11.7491C9.72301 11.8168 9.8612 11.8499 9.99978 11.8499C10.1391 11.8499 10.2771 11.8168 10.4034 11.7491L19.547 6.87307C19.8353 6.71953 20.0102 6.41467 19.9997 6.08838C19.9872 5.76145 19.7906 5.46894 19.4917 5.33606Z"
        fill="black"
      />
      <path
        d="M18.7402 8.98459L9.99916 13.6475L1.25952 8.98459C0.841129 8.76134 0.323054 8.91973 0.101299 9.33735C-0.122009 9.75477 0.0363777 10.2727 0.453608 10.4954L9.59675 15.3728C9.72284 15.44 9.86103 15.4735 9.99961 15.4735C10.1389 15.4735 10.2769 15.4405 10.4032 15.3728L19.5468 10.4954C19.963 10.2727 20.1212 9.75477 19.8992 9.33735C19.6758 8.91967 19.1573 8.76128 18.7402 8.98459Z"
        fill="black"
      />
      <path
        d="M18.7402 12.3289L9.99916 16.9903L1.25952 12.3289C0.841129 12.1055 0.323054 12.2641 0.101299 12.6811C-0.122009 13.0985 0.0363777 13.617 0.453608 13.8393L9.59675 18.7152C9.72284 18.7828 9.86103 18.8163 9.99961 18.8163C10.1389 18.8163 10.2769 18.7833 10.4032 18.7152L19.5468 13.8393C19.963 13.617 20.1212 13.0986 19.8992 12.6811C19.6758 12.2641 19.158 12.1054 18.7402 12.3289Z"
        fill="black"
      />
    </g>
  </svg>
);
export default StackSvg;
