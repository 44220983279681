export const MagicWandSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11765_72212)">
        <path
          d="M11.8691 10.2109L19.4611 17.8029C19.5707 17.9116 19.6578 18.0408 19.7173 18.1832C19.7768 18.3256 19.8076 18.4783 19.808 18.6326C19.8083 18.787 19.7782 18.9398 19.7193 19.0825C19.6604 19.2251 19.5739 19.3547 19.4647 19.4638C19.3556 19.573 19.226 19.6595 19.0834 19.7184C18.9407 19.7773 18.7879 19.8074 18.6335 19.8071C18.4792 19.8067 18.3265 19.7759 18.1841 19.7164C18.0417 19.6569 17.9125 19.5698 17.8038 19.4602L10.1977 11.8542L10.5844 10.6105L11.8691 10.2109Z"
          fill="currentColor"
        />
        <path
          d="M4.19813 7.17398L2.99024 3.61198C2.96215 3.52915 2.9578 3.44011 2.97767 3.35494C2.99755 3.26976 3.04086 3.19184 3.1027 3.13C3.16455 3.06815 3.24247 3.02484 3.32764 3.00497C3.41282 2.98509 3.50186 2.98944 3.58469 3.01753L7.14672 4.22542L10.1611 1.97589C10.2312 1.92358 10.3146 1.89192 10.4017 1.8845C10.4889 1.87708 10.5764 1.8942 10.6543 1.93391C10.7322 1.97362 10.7975 2.03434 10.8427 2.10921C10.8879 2.18408 10.9113 2.27011 10.9102 2.35757L10.8622 6.11851L13.9332 8.29026C14.0047 8.34077 14.0605 8.41024 14.0945 8.49084C14.1285 8.57143 14.1393 8.65993 14.1256 8.74632C14.1119 8.83271 14.0743 8.91355 14.0171 8.9797C13.9598 9.04584 13.8852 9.09466 13.8017 9.12062L10.21 10.2371L9.0934 13.8289C9.06743 13.9124 9.01862 13.987 8.95248 14.0442C8.88634 14.1014 8.8055 14.139 8.71912 14.1527C8.63274 14.1664 8.54425 14.1556 8.46366 14.1216C8.38307 14.0876 8.31359 14.0318 8.26309 13.9604L6.09121 10.8894L2.33028 10.9375H2.32426C2.23731 10.9375 2.15207 10.9133 2.07808 10.8677C2.00409 10.822 1.94426 10.7566 1.90528 10.6789C1.86631 10.6012 1.84972 10.5141 1.85738 10.4275C1.86503 10.3409 1.89663 10.2581 1.94864 10.1884L4.19813 7.17398Z"
          fill="currentColor"
        />
        <path
          d="M0.516203 1.37773C1.17636 1.17296 1.26062 0.899914 1.29023 0.637726C1.2967 0.580491 1.32402 0.527644 1.36698 0.489271C1.40993 0.450898 1.46552 0.429688 1.52312 0.429688C1.58072 0.429688 1.6363 0.450898 1.67926 0.489271C1.72221 0.527644 1.74954 0.580491 1.75601 0.637726C1.78566 0.899875 1.8698 1.17288 2.53003 1.37773C2.58141 1.39367 2.62573 1.4268 2.65558 1.47155C2.68542 1.51631 2.69895 1.56997 2.69392 1.62353C2.68888 1.67708 2.66557 1.72727 2.6279 1.76568C2.59024 1.80408 2.54051 1.82836 2.48706 1.83444C2.22491 1.86405 1.95191 1.94823 1.74714 2.60847C1.73232 2.65626 1.7026 2.69806 1.66232 2.72775C1.62204 2.75744 1.57331 2.77345 1.52327 2.77345C1.47323 2.77345 1.42451 2.75744 1.38423 2.72775C1.34394 2.69806 1.31422 2.65626 1.29941 2.60847C1.09464 1.94831 0.821593 1.86405 0.559484 1.83444C0.506036 1.82836 0.456307 1.80408 0.418642 1.76568C0.380977 1.72727 0.357669 1.67708 0.352631 1.62353C0.347592 1.56997 0.36113 1.51631 0.390971 1.47155C0.420812 1.4268 0.465139 1.39367 0.516515 1.37773H0.516203Z"
          fill="currentColor"
        />
        <path
          d="M2.74277 13.9168C3.40292 13.712 3.48718 13.439 3.51679 13.1768C3.52326 13.1196 3.55058 13.0667 3.59354 13.0283C3.6365 12.99 3.69208 12.9688 3.74968 12.9688C3.80728 12.9688 3.86286 12.99 3.90582 13.0283C3.94878 13.0667 3.9761 13.1196 3.98257 13.1768C4.01222 13.4389 4.09636 13.7119 4.75659 13.9168C4.80797 13.9327 4.8523 13.9659 4.88214 14.0106C4.91198 14.0554 4.92552 14.109 4.92048 14.1626C4.91544 14.2161 4.89213 14.2663 4.85447 14.3047C4.8168 14.3431 4.76707 14.3674 4.71362 14.3735C4.45148 14.4031 4.17847 14.4873 3.9737 15.1475C3.95889 15.1953 3.92916 15.2371 3.88888 15.2668C3.8486 15.2965 3.79988 15.3125 3.74984 15.3125C3.6998 15.3125 3.65107 15.2965 3.61079 15.2668C3.57051 15.2371 3.54078 15.1953 3.52597 15.1475C3.3212 14.4874 3.04816 14.4031 2.78605 14.3735C2.7326 14.3674 2.68287 14.3431 2.6452 14.3047C2.60754 14.2663 2.58423 14.2161 2.57919 14.1626C2.57415 14.109 2.58769 14.0554 2.61753 14.0106C2.64737 13.9659 2.6917 13.9327 2.74308 13.9168H2.74277Z"
          fill="currentColor"
        />
        <path
          d="M16.5884 4.60788C16.3263 4.63749 16.0533 4.72167 15.8485 5.38191C15.8337 5.4297 15.804 5.4715 15.7637 5.50119C15.7234 5.53087 15.6747 5.54689 15.6246 5.54689C15.5746 5.54689 15.5259 5.53087 15.4856 5.50119C15.4453 5.4715 15.4156 5.4297 15.4008 5.38191C15.196 4.72175 14.923 4.63749 14.6609 4.60788C14.6074 4.6018 14.5577 4.57752 14.52 4.53912C14.4823 4.50071 14.459 4.45052 14.454 4.39696C14.449 4.34341 14.4625 4.28975 14.4923 4.24499C14.5222 4.20024 14.5665 4.16711 14.6179 4.15116C15.278 3.9464 15.3623 3.67335 15.3919 3.41116C15.3984 3.35393 15.4257 3.30108 15.4687 3.26271C15.5116 3.22434 15.5672 3.20312 15.6248 3.20312C15.6824 3.20312 15.738 3.22434 15.7809 3.26271C15.8239 3.30108 15.8512 3.35393 15.8577 3.41116C15.8873 3.67331 15.9715 3.94632 16.6317 4.15116C16.6831 4.16711 16.7274 4.20024 16.7573 4.24499C16.7871 4.28975 16.8006 4.34341 16.7956 4.39696C16.7906 4.45052 16.7673 4.50071 16.7296 4.53912C16.6919 4.57752 16.6422 4.6018 16.5887 4.60788H16.5884Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
