import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntercom } from 'react-use-intercom';

import BackendService from '../services/backendService';
import { Template } from '../services/TemplateService/Template.type';
import { templatesCacheKey } from './useTemplates';

function useAddToMyLessons() {
  const client = useQueryClient();
  const { trackEvent } = useIntercom();

  const { mutate, isSuccess, isLoading, data } = useMutation(
    ({
      templateId,
      activeTenantId,
    }: {
      templateId: string;
      activeTenantId?: string;
      onSuccessFunction?: (data: Template) => void;
    }) => BackendService.instance.discover.addToMyLessons(templateId, activeTenantId),
    {
      onMutate: templateId => {
        trackEvent('Discover.AddToMyLesson', { metadata: templateId });
      },
      onSuccess: (data, { onSuccessFunction }) => {
        client.invalidateQueries([templatesCacheKey]);
        if (onSuccessFunction) {
          onSuccessFunction(data);
        }
      },
    },
  );

  return {
    addToMyLessons: mutate,
    isSuccess,
    isLoading,
    data,
  };
}

export default useAddToMyLessons;
