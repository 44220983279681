import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EmojiThanks from '../assets/icons/EmojiThanks';
import { Button } from '../components/_atoms/Button';
import { IconWrapper } from '../components/_atoms/IconWrapper';
import BaseModal from '../components/_molecules/BaseModal';
import { useMarketingMetrics } from '../providers/PlausibleAnalyticsProvider';
import { CenteredContainer } from '../styles/CenteredContainer';
import useSignIn from './useSignIn';

function useAuthGuardPrompt(
  templateId?: string,
  creatorId?: string,
  generatorId?: string,
) {
  const [showAuthGuard, setShowAuthGuard] = useState(false);
  const { t } = useTranslation('Host');
  const router = useRouter();
  const { track } = useMarketingMetrics();
  const promptAuthGuard = useCallback(() => {
    track('AuthGuard.Discover.Prompted', {
      props: {
        templateId,
        creatorId,
        generatorId,
      },
    });
    setShowAuthGuard(true);
  }, [creatorId, templateId, track, generatorId]);
  const hideAuthGuard = useCallback(() => {
    track('AuthGuard.Discover.Dismissed', {
      props: {
        templateId,
        creatorId,
        generatorId,
      },
    });
    setShowAuthGuard(false);
  }, [creatorId, templateId, track, generatorId]);
  const { signIn } = useSignIn();

  const authGuardComponent = useMemo(
    () => (
      <BaseModal
        title={t('You need to be logged in')}
        visible={showAuthGuard}
        onCancel={hideAuthGuard}
      >
        <CenteredContainer style={{ flexDirection: 'column' }}>
          <IconWrapper height="150px" width="150px">
            <EmojiThanks />
          </IconWrapper>
          <p
            style={{
              marginTop: 20,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {t(
              'InOrderTo',
              'In order to get the most out of Curipod. Please log into your account or create a new one.',
            )}
          </p>
        </CenteredContainer>
        <CenteredContainer>
          <Button
            width="160px"
            height="42px"
            type="ghost"
            onClick={() => {
              track('AuthGuard.Discover.SignIn', {
                props: {
                  templateId,
                  creatorId,
                  generatorId,
                },
              });
              signIn();
            }}
          >
            {t('Login')}
          </Button>

          <Button
            height="42px"
            minWidth="160px"
            styles={{ paddingTop: '11.5px !important', marginLeft: 20 }}
            type="primary"
            onClick={() => {
              track('AuthGuard.Discover.SignUp', {
                props: {
                  templateId,
                  creatorId,
                  generatorId,
                },
              });
              router.push({
                pathname: '/signup',
                query: { callback: window.location.href },
              });
            }}
          >
            {t('SignupItFree', "Sign up - It's free")}
          </Button>
        </CenteredContainer>
      </BaseModal>
    ),
    [
      t,
      showAuthGuard,
      hideAuthGuard,
      templateId,
      creatorId,
      signIn,
      router,
      track,
      generatorId,
    ],
  );

  return {
    showAuthGuard: promptAuthGuard,
    hideAuthGuard,
    authGuardComponent,
  };
}

export default useAuthGuardPrompt;
