import { useQuery } from '@tanstack/react-query';

import backendService from '../services/backendService';

export const frontPageTemplatesCacheKey = ['front-page-templates'];
function useDiscoverFrontPageTemplates() {
  const { data, isFetching } = useQuery(
    frontPageTemplatesCacheKey,
    async _ => {
      return backendService.instance.discover.getFrontPageTemplates();
    },
    {
      staleTime: 1000 * 60 * 5,
    },
  );

  return {
    result: data,
    isFetching,
  };
}

export default useDiscoverFrontPageTemplates;
