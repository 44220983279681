import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';

import theme from '../assets/theme';
import { parseSlateStringToText } from '../components/SlideComponents/slateUtil';
import { MediaMimeType, Slide } from '../components/SlideComponents/slide.types';
import {
  retrieveSlideQuestionElement,
  retrieveSlideQuestionElementSlate,
} from '../components/SlideComponents/slideUtil';
import { ActiveGame, PresentationDTO, Round } from '../services/backendService/types';
import { Folder, Template } from '../services/TemplateService/Template.type';
import { generateUniqueId } from './creator';

export const MAX_POLL_OPTIONS = 10;

export function findRoundsByReferencesReverse(startRoundId: string, rounds: Round[]) {
  const startRound = rounds.find(r => r.id === startRoundId);
  if (!startRound) {
    return [];
  }

  const tempRounds = [startRound];

  while (tempRounds.length <= 3) {
    const nextRound = rounds.find(r => r.dataReferenceRoundId === tempRounds[0].id);
    if (nextRound) {
      tempRounds.unshift(nextRound);
    } else {
      break;
    }
  }
  return tempRounds.reverse();
}

export function listRoundsByReferences(startRoundId: string, rounds: Round[]) {
  const startRound = rounds.find(r => r.id === startRoundId);

  if (!startRound) {
    return [];
  }

  let dataReferenceRoundId = startRound.dataReferenceRoundId;
  const tempRounds = [startRound] as Round[];
  while (dataReferenceRoundId) {
    // eslint-disable-next-line no-loop-func
    const relatedRound = rounds.find(r => r.id === dataReferenceRoundId);
    if (relatedRound) {
      tempRounds.unshift(relatedRound);
      dataReferenceRoundId = relatedRound.dataReferenceRoundId;
    } else {
      dataReferenceRoundId = undefined;
    }
  }
  return tempRounds;
}
export function findRelatedRounds(startRoundId: string, rounds: Round[]) {
  const referenceRounds = findRelatedRoundsWithoutIndex(startRoundId, rounds);

  return referenceRounds.map(r => ({
    ...r,
    index: rounds.findIndex(round => round.id === r.id) + 1,
  }));
}

export function findRelatedRoundsWithoutIndex(startRoundId: string, rounds: Round[]) {
  const reverseRounds = findRoundsByReferencesReverse(startRoundId, rounds);
  if (reverseRounds.length === 0) {
    return [];
  }
  const last = reverseRounds[reverseRounds.length - 1];

  const referenceRounds = listRoundsByReferences(last.id, rounds);
  return referenceRounds;
}

export function getMediaAspectRatio(mimeType?: MediaMimeType) {
  if (mimeType === 'iframe/video') return VIDEO_ASPECT_RATIO;
  return 16 / 8;
}

export const VIDEO_ASPECT_RATIO = 16 / 9;
export const createStickyNote = ({
  scrollX,
  color = theme.colors.orange,
  height,
  scrollY,
  width,
  zoom,
  defaultText,
}: {
  color?: string;
  scrollX: number;
  scrollY: number;
  height: number;
  width: number;
  zoom: number;
  defaultText: string;
}): ExcalidrawElement[] => {
  const textId = generateUniqueId().toString();
  const rectangleId = generateUniqueId().toString();

  const textHeight = 25;
  const rectangleWidth = 200;
  const textWidth = rectangleWidth - 10;
  const rectangleHeight = rectangleWidth - textHeight;

  const viewPortCenterX = width / zoom / 2 - scrollX;
  const viewPortCenterY = height / zoom / 2 - scrollY;
  const stickyNoteX = viewPortCenterX - rectangleWidth / 2;
  const stickyNoteY = viewPortCenterY - rectangleHeight / 2;
  const stickyNoteTextX = stickyNoteX + 5;
  const stickyNoteTextY = stickyNoteY + rectangleHeight / 2 - textHeight / 2;

  return [
    {
      angle: 0,
      backgroundColor: color,
      boundElements: [{ id: textId, type: 'text' }],
      fillStyle: 'solid',
      groupIds: [],
      height: rectangleHeight,
      id: rectangleId,
      isDeleted: false,
      link: null,
      locked: false,
      opacity: 100,
      roughness: 1,
      seed: 393274176,
      strokeColor: 'transparent',
      strokeSharpness: 'sharp',
      strokeStyle: 'solid',
      strokeWidth: 1,
      type: 'rectangle',
      updated: 1656837112487,
      version: 14,
      versionNonce: 743356608,
      width: rectangleWidth,
      x: stickyNoteX,
      y: stickyNoteY,
    },
    {
      angle: 0,
      backgroundColor: 'transparent',
      baseline: 18,
      boundElements: null,
      containerId: rectangleId,
      fillStyle: 'solid',
      fontFamily: 1,
      fontSize: 20,
      groupIds: [],
      height: textHeight,
      id: textId,
      isDeleted: false,
      link: null,
      locked: false,
      opacity: 100,
      originalText: defaultText,
      roughness: 1,
      seed: 1354226496,
      strokeColor: '#000000',
      strokeSharpness: 'sharp',
      strokeStyle: 'solid',
      strokeWidth: 1,
      text: defaultText,
      textAlign: 'center',
      type: 'text',
      updated: 1656836955422,
      version: 12,
      versionNonce: 382927040,
      verticalAlign: 'middle',
      width: textWidth,
      x: stickyNoteTextX,
      y: stickyNoteTextY,
    },
  ];
};

export const getTitle = ({
  title,
  slides,
  maxLength,
}: {
  title?: string | null;
  slides?: Slide[] | null;
  maxLength?: number;
}): string | 'Untitled' => {
  if (!!title && title !== null) {
    const titleToReturn = parseSlateStringToText(title);
    if (maxLength && titleToReturn.length > maxLength)
      return `${titleToReturn.slice(0, maxLength)}...`;
    return parseSlateStringToText(title);
  }
  if (!slides || slides.length === 0 || slides === null) {
    return 'Untitled';
  }

  const firstRoundTitle = slides.find(round => {
    const t = parseSlateStringToText(retrieveSlideQuestionElementSlate(round));
    if (t.length > 0) {
      return true;
    }
    return false;
  });

  const slideTitleElement = firstRoundTitle
    ? retrieveSlideQuestionElement(firstRoundTitle)
    : undefined;
  const titleToReturn = slideTitleElement
    ? parseSlateStringToText(slideTitleElement.textValue)
    : ('Untitled' as string);

  if (maxLength && titleToReturn.length > maxLength)
    return `${titleToReturn.slice(0, maxLength)}...`;
  return titleToReturn;
};

export const convertPresentationDTOToTemplateDataType = (
  presentation: PresentationDTO,
): Template => {
  return {
    id: presentation.id,
    tenantId: presentation.tenantId,
    type: 'template',
    parentId: presentation.parentId,
    title: presentation.content.title || null,
    description: presentation.content.description || null,
    lastPublished: presentation?.content.lastPublished,
    isPrivate: presentation.isPrivate,
    createdAt: new Date(presentation.createdAt).getTime(),
    modifiedAt: presentation.modifiedAt
      ? new Date(presentation.modifiedAt).getTime()
      : new Date(presentation.createdAt).getTime(),
    creator: {
      uid: presentation.creatorId,
    },
    templateSharingPublic: presentation.content.templateSharingPublic,
    isBlockedFromSharing: presentation.content.isBlockedFromSharing,
    members: [],
    metadata: presentation.content.metadata,
    clonedFromTemplateId: presentation.content.clonedFromPresentationId,
    coverImageAltText: presentation.content.coverImageAltText,
    coverImageId: presentation.content.coverImageId,
    currentRound: presentation.content.currentSlideId,
    deletedAt: presentation.deletedAt ? new Date(presentation.deletedAt).getTime() : null,
    isDeleted: presentation.isDeleted,
    language: presentation.content.language,
    requireRealNames: presentation.content.requireRealNames,
    content: {
      rounds: presentation.content.deprecatedRounds,
      slides: presentation.content.slides,
      gameIds: presentation.content.livePresentationIds,
      activeGame: presentation.content.activeLivePresentation,
    },
    aiContent: presentation.aiContent,
  };
};

export const convertTemplateDataTypeToPresentationDTO = (
  templates: Template[],
): PresentationDTO[] => {
  return templates.map(template => {
    return {
      id: template.id,
      tenantId: template.tenantId,
      createdAt: template.createdAt ? new Date(template.createdAt) : new Date(),
      modifiedAt: template.modifiedAt ? new Date(template.modifiedAt) : new Date(),
      creatorId: template.creator.uid,
      parentId: template.parentId,
      isPrivate: template.isPrivate,
      isDeleted: template.isDeleted || false,
      deletedAt: template.deletedAt ? new Date(template.deletedAt) : null,
      aiContent: template.aiContent,
      editCounter: null,
      content: {
        currentSlideId: template.currentRound || undefined,
        slides: template.content.slides || [],
        deprecatedRounds: template.content.rounds || [],
        title: template.title || undefined,
        description: template.description || undefined,
        lastPublished: template.lastPublished || undefined,
        livePresentationIds: template.content.gameIds || [],
        coverImageId: template.coverImageId || undefined,
        coverImageAltText: template.coverImageAltText || undefined,
        templateSharingPublic: template.templateSharingPublic || false,
        isBlockedFromSharing: template.isBlockedFromSharing || false,
        language: template.language || 'en',
        requireRealNames: template.requireRealNames || true,
        metadata: template.metadata || {},
        // activeLivePresentations: [], //template does not have active live presentations
        activeLivePresentation: template.content.activeGame || undefined,
        clonedFromPresentationId: template.clonedFromTemplateId || undefined,
      },
    };
  });
};

export const isTemplate = (element: Folder | Template): element is Template => {
  if ((element as Template).type === 'template') return true;
  return false;
};
