import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import BackendService from '../services/backendService';
import { Template } from '../services/TemplateService/Template.type';
import { deletedWorkspaceTemplatesCacheKey } from './useDeletedWorkspaceTemplates';
import useDuplicateTemplate from './useDuplicateTemplate';
import { useMetrics } from './useMetrics';
import useWorkSpace from './useWorkSpace';
export const workspaceTemplatesCacheKey = 'workspaceTemplates';
function useWorkspaceTemplates(parentId?: string) {
  const client = useQueryClient();

  const { workspaceId } = useWorkSpace();
  const cacheKey = [workspaceTemplatesCacheKey, workspaceId, parentId];
  const metrics = useMetrics();
  const { data: templates, isFetching: isFetchingTemplates } = useQuery(
    cacheKey,
    async _ => {
      if (!workspaceId) return Promise.resolve(undefined);
      const templates = await BackendService.instance.workspace.listWorkspaceTemplates(
        workspaceId,
        parentId,
      );
      return templates;
    },
    {
      staleTime: 5000,
      refetchInterval: 5000,
      enabled: Boolean(workspaceId),
    },
  );

  const { mutate: deleteTemplate, isLoading: isDeletingTemplate } = useMutation(
    async (template: Template) => {
      metrics.logEvent('Template.Delete', { id: template.id });
      return await BackendService.instance.template.deleteTemplate(template.id);
    },
    {
      onSettled: () => {
        client.invalidateQueries(cacheKey);
      },
      onMutate: async template => {
        await client.cancelQueries(cacheKey);
        client.setQueryData<Template[] | undefined>(cacheKey, templates => {
          if (!templates) return templates;
          return templates.filter(t => t.id !== template.id);
        });
        client.setQueryData<Template[] | undefined>(
          [deletedWorkspaceTemplatesCacheKey, workspaceId, null],
          templates => {
            if (!templates) return [template];
            return [...templates, template];
          },
        );

        // Return the snapshotted value
      },
      onError: (err, bodyWithError, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );

  const { duplicateTemplate, isDuplicatingTemplate } = useDuplicateTemplate(parentId);

  return {
    templates,
    isFetchingTemplates,
    deleteTemplate,
    isDeletingTemplate,
    duplicateTemplate,
    isDuplicatingTemplate,
  };
}
export default useWorkspaceTemplates;
