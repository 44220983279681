import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { LogoSvg } from '../../assets/icons';
import theme from '../../assets/theme';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import useSignIn from '../../hooks/useSignIn';
import { zIndexes } from '../../utils/zIndexes';
import { Button } from '../_atoms/Button';

const AnonymisedMobileTopbar = ({
  close,
  isLoadingAuth,
}: {
  close: () => void;
  isLoadingAuth: boolean;
}) => {
  const router = useRouter();
  const isDesktop = useIsDesktop();
  const { signIn } = useSignIn();
  const { t } = useTranslation('Host');
  return (
    <Container>
      <WidthContainer>
        <GroupContainer $includeMargin>
          <ShortLogoContainer
            style={{ color: theme.colors.white }}
            onClick={() => close()}
          >
            <LogoSvg />
          </ShortLogoContainer>
        </GroupContainer>
        <GroupContainer>
          {!isLoadingAuth && (
            <StyledLink
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                color: theme.colors.white,
                marginRight: 20,
                cursor: 'pointer',
              }}
              onClick={() => signIn()}
            >
              {t('Log in')}
            </StyledLink>
          )}
          {!isLoadingAuth && (
            <Button
              type="primary"
              styles={{
                fontWeight: 500,
                fontSize: 18,
              }}
              onClick={() => {
                router.push({
                  pathname: '/signup',
                  query: {
                    callback: window.location.href,
                  },
                });
              }}
            >
              {isDesktop
                ? t('signuplong', { defaultValue: 'Sign up - It`s free' })
                : t('Signup')}
            </Button>
          )}
        </GroupContainer>
      </WidthContainer>
    </Container>
  );
};

const ShortLogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 25px;
  }
`;

const StyledLink = styled.span`
  font-weight: 500;

  &:hover {
    color: black !important;
  }
`;

const RootGroupContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const GroupContainer = styled(RootGroupContainer)<{
  $includeMargin?: boolean;
}>`
  ${({ $includeMargin }) =>
    $includeMargin &&
    css`
      & > * {
        margin: 0 10px;
      }
    `}

  & > *:last-child {
    margin: 0px !important;
  }
`;

const WidthContainer = styled.div`
  color: white;
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  z-index: ${zIndexes('TOPBAR')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.orange};
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);
  height: 60px;
  min-height: 60px;
`;

export default AnonymisedMobileTopbar;
