const HeartAttackSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6549_60323)">
      <path
        d="M19.791 5.51895C19.4579 4.21265 18.7479 3.08624 17.7377 2.26151C16.7537 1.45813 15.5481 1.01562 14.3434 1.01562C13.4198 1.01562 12.5386 1.26434 11.7245 1.75476C11.0817 2.14218 10.5035 2.66907 9.99831 3.32672C8.84948 1.82938 7.33275 1.01562 5.6552 1.01562C4.45082 1.01562 3.24584 1.45813 2.26195 2.26151C1.25212 3.08624 0.542281 4.21265 0.209182 5.5188C-0.15291 6.93924 -0.2231 9.2093 1.50008 11.9573C3.09508 14.5007 5.85982 16.8575 9.7177 18.9618C9.80513 19.0094 9.90157 19.0334 9.99816 19.0334C10.0947 19.0334 10.1912 19.0096 10.2786 18.9618C14.1376 16.8573 16.9032 14.5006 18.4988 11.9573C20.2228 9.2093 20.153 6.9394 19.791 5.51895ZM9.99816 17.7786C5.42525 15.2336 3.16237 12.7095 2.08693 10.6104H3.72466C3.94667 10.6104 4.14946 10.4849 4.24864 10.2866L4.97969 8.82447L6.96563 12.7965C7.06481 12.995 7.26775 13.1204 7.48961 13.1204C7.71148 13.1204 7.91442 12.995 8.0136 12.7965L9.99953 8.82447L10.7306 10.2866C10.8299 10.4851 11.0327 10.6104 11.2546 10.6104H15.6976C16.0211 10.6104 16.2834 10.3481 16.2834 10.0246C16.2834 9.70109 16.0211 9.43879 15.6976 9.43879H11.6167L10.5237 7.25251C10.4243 7.05399 10.2215 6.92872 9.99953 6.92872C9.77767 6.92872 9.57488 7.05399 9.4757 7.25251L7.48961 11.2245L5.50368 7.25251C5.40435 7.05399 5.20156 6.92872 4.97969 6.92872C4.75768 6.92872 4.55489 7.05399 4.45571 7.25251L3.36256 9.43879H1.58659C1.006 7.74125 1.18407 6.43769 1.34459 5.80841C1.88826 3.67645 3.66072 2.18735 5.6552 2.18735C7.16368 2.18735 8.52813 3.0687 9.49706 4.66873C9.60311 4.84406 9.79308 4.95117 9.99801 4.95117H9.99816C10.2029 4.95117 10.3931 4.84421 10.4993 4.66889C11.469 3.0687 12.8342 2.18735 14.3434 2.18735C16.3386 2.18735 18.1119 3.67645 18.6555 5.80841C19.0326 7.2873 19.5074 12.4875 9.99816 17.7786Z"
        fill="black"
      />
    </g>
  </svg>
);

export default HeartAttackSvg;
