import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QuestionMarkSvg } from '../../../assets/icons';
import useRouterMatch from '../../../hooks/useRouterMatch';
import useWorkSpace from '../../../hooks/useWorkSpace';
import { Input } from '../../_atoms/_Input';
import { Button } from '../../_atoms/Button';
import { Center } from '../../_atoms/Center';
import { IconWrapper } from '../../_atoms/IconWrapper';
import BaseModal from '../../_molecules/BaseModal';

const CreateTeam = ({ visible, onDone }: { visible: boolean; onDone: () => void }) => {
  const { tenantId, router } = useRouterMatch();
  const { createWorkspace, isCreatingWorkspace } = useWorkSpace();
  const { t } = useTranslation('Host');
  const [missingWorkspaceName, setMissingWorkspaceName] = useState<string | undefined>();
  const [workspaceNameInput, setWorkspaceNameInput] = useState('');
  const handleCreateWorkspace = () => {
    if (workspaceNameInput.length === 0) {
      setMissingWorkspaceName(t('Please enter a name'));
      return;
    }
    if (!isCreatingWorkspace) {
      createWorkspace({
        workspaceConfig: {
          title: workspaceNameInput,
        },
        onSuccessFunction: data => {
          onDone();
          router.push(`/${tenantId}/workspace/${data.id}`);
        },
      });
    }
  };

  return (
    <BaseModal visible={visible} onCancel={onDone}>
      <CreateWorkspaceModalContainer>
        <CreateWorkspaceModalTitle>{t('Create team')}</CreateWorkspaceModalTitle>
        <p>
          {t(
            'OrganiseYourPresenters',
            'Work together with your team in a shared workspace with centralized billing. Create shared lesson templates and collaborate with others.',
          )}
        </p>
        <p>
          <a
            target="__blank"
            rel="noopener noreferrer"
            href="https://curipod.com/help/What is a workspace-2ea7e17e100944c3bd8eb8d74862081e"
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              <IconWrapper height="20px" width="20px" style={{ marginRight: '10px' }}>
                <QuestionMarkSvg />
              </IconWrapper>{' '}
              {t('Learn more about teams and workspaces')}
            </span>
          </a>
        </p>
        <CreateWorkspaceModalSubTitle>{t('Workspace name')}</CreateWorkspaceModalSubTitle>
        <Input
          style={{ width: '100%', marginBottom: '10px' }}
          placeholder={t('Enter a name for your workspace')}
          value={workspaceNameInput}
          maxLength={30}
          error={missingWorkspaceName}
          onPressEnter={handleCreateWorkspace}
          onChange={e => {
            setMissingWorkspaceName(undefined);
            setWorkspaceNameInput(e.target.value);
          }}
        />
        <Center>
          <Button disabled={isCreatingWorkspace} onClick={handleCreateWorkspace}>
            {isCreatingWorkspace && <LoadingOutlined />}
            {t(isCreatingWorkspace ? 'Creating' : 'Create')}
          </Button>
        </Center>
      </CreateWorkspaceModalContainer>
    </BaseModal>
  );
};

export default CreateTeam;

const CreateWorkspaceModalTitle = styled.h1`
  width: 100%;
  text-align: left !important;
`;

const CreateWorkspaceModalSubTitle = styled.h2`
  width: 100%;
  text-align: left !important;
  font-weight: bold;
`;

const CreateWorkspaceModalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  font-weight: bold;
`;
