import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import BackendService from '../services/backendService';
import { Template } from '../services/TemplateService/Template.type';
import { useMetrics } from './useMetrics';
import useRouterMatch from './useRouterMatch';
import useWorkSpace from './useWorkSpace';
import { workspaceTemplatesCacheKey } from './useWorkspaceTemplates';
export const deletedWorkspaceTemplatesCacheKey = 'deletedWorkspaceTemplates';
function useDeletedWorkspaceTemplates(parentId?: string) {
  const { workspaceId } = useWorkSpace();
  const cacheKey = [deletedWorkspaceTemplatesCacheKey, workspaceId, parentId];
  const metrics = useMetrics();
  const client = useQueryClient();
  const { data: templates, isFetching: isFetchingTemplates } = useQuery(
    cacheKey,
    async _ => {
      if (!workspaceId) return Promise.resolve(undefined);
      const templates = await BackendService.instance.workspace.listDeletedWorkspaceTemplates(
        workspaceId,
      );
      return templates;
    },
    { staleTime: 10000, refetchInterval: 10000, enabled: Boolean(workspaceId) },
  );
  const { mutate: restoreTemplate, isLoading: isRestoringTemplate } = useMutation(
    ({
      templateId,
    }: {
      templateId: string;
      onSuccessFunction?: (data: Template) => void | undefined;
    }) => {
      if (!workspaceId) return Promise.resolve(undefined);
      metrics.logEvent('Template.Restore', { id: templateId });
      return BackendService.instance.workspace.restoreWorkspaceTemplate(
        workspaceId,
        templateId,
      );
    },
    {
      onSettled: () => {
        client.invalidateQueries(cacheKey);
        client.invalidateQueries([workspaceTemplatesCacheKey, workspaceId, parentId]);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        if (onSuccessFunction && data) {
          onSuccessFunction(data);
        }
      },
    },
  );

  return {
    templates,
    restoreTemplate,
    isRestoringTemplate,
    isFetchingTemplates,
  };
}
export default useDeletedWorkspaceTemplates;
