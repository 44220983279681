const FolderSvg = ({ height, width }: { height?: number; width?: number }) => {
  const _height = height ? height : 30;
  const _width = width ? width : 30;

  return (
    <svg
      width={_width}
      height={_height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5357 3.36926H11.2057C10.9487 3.36926 10.6978 3.26375 10.5244 3.08441L8.54932 1.00379C8.07456 0.53055 7.42499 0.283363 6.7671 0.287152H2.46421C1.10551 0.287113 0 1.39262 0 2.75129V14.7483C0 16.107 1.10551 17.2125 2.46421 17.2125H17.5448H17.5471C18.905 17.2065 20.006 16.0957 20 14.7407V5.83343C20 4.47472 18.8945 3.36926 17.5357 3.36926ZM18.4928 14.7438C18.4951 15.2713 18.0678 15.7031 17.5433 15.7053H2.46421C1.93672 15.7053 1.50718 15.2758 1.50718 14.7483V2.75129C1.50718 2.22379 1.93672 1.79426 2.46421 1.79348H6.78671C6.79499 1.79348 6.80327 1.79348 6.81159 1.79348C7.06327 1.79348 7.30745 1.89371 7.4717 2.05649L9.43627 4.1273C9.89744 4.60355 10.5425 4.87637 11.2057 4.87637H17.5357C18.0632 4.87637 18.4928 5.3059 18.4928 5.8334V14.7438H18.4928Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default FolderSvg;
