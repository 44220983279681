import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnOutsideClick = (
  ref: any,
  onOutSideClick: () => void,
  opts?: { classNameMatch: string[] },
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      /**
       * Adds an optional logic for slate rich text editor
       * toolbar that tries to find a dom node that contains a given classNAme and prevents closing modals.
       */
      const hasClassNameMatch = !!opts?.classNameMatch;
      // Firefox does not use event.path as it's a chrome specific, using composedPath instead which is a standard.
      const path = event.path || (event.composedPath && event.composedPath());
      const clickDoesContainClass = hasClassNameMatch
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          path?.some((p: { classList: any; className: string | string[] }) => {
            if ((p.classList as DOMTokenList)?.value) {
              return opts.classNameMatch.some(className => {
                return (p.classList as DOMTokenList).value.includes(className);
              });
            } else {
              return false;
            }
          })
        : false;

      if (ref.current && !ref.current.contains(event.target) && !clickDoesContainClass) {
        onOutSideClick();
      }
    }

    // Bind the event listener

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touched', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touched', handleClickOutside);
    };
  }, [onOutSideClick, ref, opts]);
};
