import { i18n, TFunction } from 'next-i18next';

import AgileSvg from '../assets/icons/AgileSvg';
import BrainstormSvg from '../assets/icons/BrainstormSvg';
import HeartAttackSvg from '../assets/icons/HeartAttackSvg';
import IcebreakerSvg from '../assets/icons/IcebreakerSvg';
import LaughSvg from '../assets/icons/LaughSvg';
import MathSvg from '../assets/icons/MathSvg';
import MeetingSvg from '../assets/icons/MeetingSvg';
import RaiseHandsSvg from '../assets/icons/RaiseHandsSvg';
import SignSvg from '../assets/icons/SignSvg';
import StartupSvg from '../assets/icons/StartupSvg';
import SustainabilitySvg from '../assets/icons/SustainabilitySvg';
import WorkshopSvg from '../assets/icons/WorkshopSvg';

export type CheckboxOption = {
  value: string;
  name: string;
  icon?: React.ReactNode;
  toolTip?: string;
};

export const NORWEGIAN = { value: 'nb-NO', name: 'Norwegian' };
export const ENGLISH = { value: 'en', name: 'English' };

export const ONE_TO_FOUR = { value: '1-4', name: '1-4' };
export const FIVE_TO_SEVEN = { value: '5-7', name: '5-7' };
export const EIGHT_TO_TEN = { value: '8-10', name: '8-10' };
export const ELEVEN_TO_THIRTEEN = { value: '11-13', name: '11-13' };
export const HIGHER_ED = { value: 'higher-ed', name: 'Higher ed' };

export const MEETINGS_WORKSHOPS = {
  value: 'meetings-&-workshops',
  name: 'Meetings & Workshops',
  icon: <MeetingSvg />,
};
export const BRAINSTORMING = {
  value: 'brainstorming',
  name: 'Brainstorming & ideation',
  icon: <BrainstormSvg />,
};
export const ONBOARDING = {
  value: 'onboarding',
  name: 'Onboarding & Training',
  icon: <WorkshopSvg />,
};
export const AGILE = {
  value: 'agile',
  name: 'Agile workflows',
  icon: <AgileSvg />,
};
export const ICEBREAKER = {
  value: 'icebreaker',
  name: 'Ice breakers',
  icon: <IcebreakerSvg />,
};
export const SEL = {
  value: 'sel',
  name: 'Health and SEL',
  icon: <HeartAttackSvg />,
  toolTip: {
    key: 'LessonsWithinPhysical',
    display: 'Physical and mental health, sexuality and gender, consumerism and media',
  },
};
export const MATH = {
  value: 'mathematics',
  name: 'Math',
  icon: <MathSvg />,
  toolTip: {
    key: 'WorksheetsAndActivities',
    display: 'Worksheets and activities for mathematics',
  },
};
export const CHECKIN = {
  value: 'checkin',
  name: 'Check-ins',
  icon: <SignSvg />,
  toolTip: {
    key: 'activitiesToStart',
    display: 'Activities to start and end the class, introduce a new topic',
  },
};
export const BREAK = {
  value: 'break',
  name: 'Mental breaks',
  icon: <LaughSvg />,
  toolTip: { key: 'FunActivites', display: 'fun activities to reset the mind' },
};
export const CREATIVITY = {
  value: 'creativity',
  name: 'Creativity and exploration',
  icon: <StartupSvg />,
  toolTip: { key: 'CreativeActivities', display: 'Creative activities and experiments' },
};
export const UTFORSK = {
  value: 'utforsk',
  name: 'Creativity and exploration',
  icon: <StartupSvg />,
  toolTip: { key: 'CreativeActivities', display: 'Creative activities and experiments' },
};
export const SUSTAINABILITY = {
  value: 'sustainability',
  name: 'Sustainable development',
  icon: <SustainabilitySvg />,
  toolTip: {
    key: 'EnvironmentClimate',
    display: 'Environment, climate, poverty and resources, sustainability',
  },
};
export const BEAREKRAFTING = {
  value: 'bærekraft',
  name: 'Sustainable development',
  icon: <SustainabilitySvg />,
  toolTip: {
    key: 'EnvironmentClimate',
    display: 'Environment, climate, poverty and resources, sustainability',
  },
};

export const FOLKEHELSE = {
  value: 'folkehelse&livsmestring',
  name: 'Health and SEL',
  icon: <HeartAttackSvg />,
  toolTip: {
    key: 'LessonsWithinPhysical',
    display: 'Physical and mental health, sexuality and gender, consumerism and media',
  },
};
export const DEMOKRATI = {
  value: 'demokrati',
  name: 'Democracy and citizenship',
  icon: <RaiseHandsSvg />,
  toolTip: {
    key: 'DemocraxyAndCity',
    display: 'Democracy and citizenship (Conflicts, freedom of speech, ethics)',
  },
};

export const INNUTSJEKK = {
  value: 'inn&utsjekk',
  name: 'Check-ins',
  icon: <SignSvg />,
  toolTip: {
    key: 'activitiesToStart',
    display: 'Activities to start and end the class, introduce a new topic',
  },
};
export const AVBREKK = {
  value: 'avbrekk',
  name: 'Mental breaks',
  icon: <LaughSvg />,
  toolTip: {
    key: 'FunActivites',
    display: 'fun activities to reset the mind',
  },
};

export const LanguageMapper = (t: TFunction): CheckboxOption[] => [
  { value: ENGLISH.value, name: t(ENGLISH.name) },
  { value: NORWEGIAN.value, name: t(NORWEGIAN.name) },
];

export const GradesMapper = (t: TFunction): CheckboxOption[] => [
  { value: ONE_TO_FOUR.value, name: ONE_TO_FOUR.name },
  { value: FIVE_TO_SEVEN.value, name: FIVE_TO_SEVEN.name },
  { value: EIGHT_TO_TEN.value, name: EIGHT_TO_TEN.name },
  { value: ELEVEN_TO_THIRTEEN.value, name: ELEVEN_TO_THIRTEEN.name },
  { value: HIGHER_ED.value, name: t(HIGHER_ED.name) },
];

export const listHotfilters = (t: TFunction, isBusiness = false): CheckboxOption[] => {
  const lang = i18n?.language;
  if (isBusiness) {
    return [
      {
        value: MEETINGS_WORKSHOPS.value,
        name: MEETINGS_WORKSHOPS.name,
        icon: MEETINGS_WORKSHOPS.icon,
      },
      {
        value: BRAINSTORMING.value,
        name: BRAINSTORMING.name,
        icon: BRAINSTORMING.icon,
      },
      {
        value: ONBOARDING.value,
        name: ONBOARDING.name,
        icon: ONBOARDING.icon,
      },
      {
        value: AGILE.value,
        name: AGILE.name,
        icon: AGILE.icon,
      },
      {
        value: ICEBREAKER.value,
        name: ICEBREAKER.name,
        icon: ICEBREAKER.icon,
      },
    ];
  }

  if (lang === 'en') {
    return [
      {
        value: SEL.value,
        name: t(SEL.name),
        icon: SEL.icon,
        toolTip: t(SEL.toolTip.key, SEL.toolTip.display),
      },
      {
        value: MATH.value,
        name: t(MATH.name),
        icon: MATH.icon,
        toolTip: t(MATH.toolTip.key, MATH.toolTip.display),
      },
      {
        value: CHECKIN.value,
        name: t(CHECKIN.name),
        icon: CHECKIN.icon,
        toolTip: t(CHECKIN.toolTip.key, CHECKIN.toolTip.display),
      },
      {
        value: BREAK.value,
        name: t(BREAK.name),
        icon: BREAK.icon,
        toolTip: t(BREAK.toolTip.key, BREAK.toolTip.display),
      },
      {
        value: CREATIVITY.value,
        name: CREATIVITY.name,
        icon: CREATIVITY.icon,
        toolTip: t(CREATIVITY.toolTip.key, CREATIVITY.toolTip.display),
      },
      {
        value: SUSTAINABILITY.value,
        name: t(SUSTAINABILITY.name),
        icon: SUSTAINABILITY.icon,
        toolTip: t(SUSTAINABILITY.toolTip.key, SUSTAINABILITY.toolTip.display),
      },
    ];
  }

  return [
    {
      value: BEAREKRAFTING.value,
      name: t(BEAREKRAFTING.name),
      icon: BEAREKRAFTING.icon,
      toolTip: t(BEAREKRAFTING.toolTip.key, BEAREKRAFTING.toolTip.display),
    },

    {
      value: FOLKEHELSE.value,
      name: t(FOLKEHELSE.name),
      icon: FOLKEHELSE.icon,
      toolTip: t(FOLKEHELSE.toolTip.key, FOLKEHELSE.toolTip.display),
    },
    {
      value: DEMOKRATI.value,
      name: t(DEMOKRATI.name),
      icon: DEMOKRATI.icon,
      toolTip: t(DEMOKRATI.toolTip.key, DEMOKRATI.toolTip.display),
    },

    {
      value: INNUTSJEKK.value,
      name: t(INNUTSJEKK.name),
      icon: INNUTSJEKK.icon,
      toolTip: t(INNUTSJEKK.toolTip.key, INNUTSJEKK.toolTip.display),
    },

    {
      value: AVBREKK.value,
      name: t(AVBREKK.name),
      icon: AVBREKK.icon,
      toolTip: t(AVBREKK.toolTip.key, AVBREKK.toolTip.display),
    },
    {
      value: UTFORSK.value,
      name: t(UTFORSK.name),
      icon: UTFORSK.icon,
      toolTip: t(UTFORSK.toolTip.key, UTFORSK.toolTip.display),
    },
  ];
};

export const adminTags = [
  SUSTAINABILITY,
  BEAREKRAFTING,
  SEL,
  BRAINSTORMING,
  FOLKEHELSE,
  AGILE,
  DEMOKRATI,
  ICEBREAKER,
  MEETINGS_WORKSHOPS,
  CHECKIN,
  ONBOARDING,
  INNUTSJEKK,
  MATH,
  BREAK,
  AVBREKK,
  UTFORSK,
  ONE_TO_FOUR,
  FIVE_TO_SEVEN,
  EIGHT_TO_TEN,
  HIGHER_ED,
];
