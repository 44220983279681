import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import { Template } from '../services/TemplateService/Template.type';
import { convertPresentationDTOToTemplateDataType } from '../utils/templateUtils';
export const presentationsCacheKey = 'presentations';

export const currentPresentationsCacheKey = ({
  parentId,
  isPrivate,
  orderBy,
  sortDirection,
}: {
  parentId?: string;
  isPrivate: boolean;
  orderBy?: 'modifiedAt' | 'title';
  sortDirection?: 'asc' | 'desc';
}) => [presentationsCacheKey, isPrivate, parentId, orderBy, sortDirection];

function usePresentations({
  parentId,
  isPrivate,
  orderBy,
  sortDirection,
}: {
  parentId?: string;
  isPrivate: boolean;
  orderBy?: 'modifiedAt' | 'title';
  sortDirection?: 'asc' | 'desc';
}) {
  const QUERY_LIMIT = 10;

  const cacheKey = currentPresentationsCacheKey({
    isPrivate,
    orderBy,
    parentId,
    sortDirection,
  });

  const client = useQueryClient();

  const {
    data: presentations,
    isFetching,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    cacheKey,
    ({ pageParam = undefined }: { pageParam?: string }) =>
      BackendService.instance.presentations.list({
        isPrivate,
        cursor: pageParam,
        limit: QUERY_LIMIT,
        orderBy,
        parentId,
        sortDirection,
      }),
    {
      getNextPageParam: lastPage => lastPage.cursor,
      staleTime: 1000 * 60 * 60,
      onSuccess: data => {
        data.pages.forEach(page => {
          page.items.forEach(presentation => {
            const template: Template = convertPresentationDTOToTemplateDataType(
              presentation,
            );
            client.setQueryData(['template', template.id], template);
          });
        });
      },
    },
  );

  const allPresentations = useMemo(
    () => presentations?.pages?.flatMap(page => page.items) || undefined,
    [presentations?.pages],
  );

  return {
    presentations: allPresentations,
    isFetching,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
}
export default usePresentations;
