import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import BackendService from '../services/backendService';
import { Workspace, WorkspaceEditDTO } from '../services/TemplateService/Template.type';
import { useMetrics } from './useMetrics';
import useRouterMatch from './useRouterMatch';

const useWorkSpace = () => {
  const router = useRouter();
  const client = useQueryClient();
  const { tenantId } = useRouterMatch();

  const metrics = useMetrics();

  const workspaceCacheKey: string[] = ['workspace', tenantId];

  // Workspace folder for tenant
  const { data: workspace, isFetching } = useQuery<WorkspaceEditDTO | undefined>(
    workspaceCacheKey,
    async _ => {
      if (!tenantId) return Promise.resolve(undefined);
      const workspaces = await BackendService.instance.workspace.listWorkspaces(tenantId);
      return workspaces && workspaces.length > 0 ? workspaces[0] : undefined;
    },
    {
      enabled: Boolean(tenantId),
      staleTime: 1000 * 60 * 60,
    },
  );

  const { mutate: createWorkspace, isLoading: isCreatingWorkspace } = useMutation(
    ({
      workspaceConfig,
    }: {
      workspaceConfig: { title: string };
      onSuccessFunction?: (data: Workspace) => void | undefined;
    }) => {
      metrics.logEvent('Workspace.Create');
      return BackendService.instance.workspace.newWorkspace({
        ...workspaceConfig,
      });
    },
    {
      onSuccess: (data, { onSuccessFunction }) => {
        client.invalidateQueries(workspaceCacheKey);
        if (onSuccessFunction && data) {
          onSuccessFunction(data);
        }
      },
    },
  );

  const { mutate: editWorkspace, isSuccess: isEditWorkspaceSuccess } = useMutation(
    (config: { editedWorkspace: { title: string }; onSuccess?: () => void }) => {
      if (!workspace) return Promise.resolve(undefined);
      return BackendService.instance.workspace.saveWorkspace(
        config.editedWorkspace,
        workspace.id,
      );
    },
    {
      onMutate: mutateBody => {
        client.setQueryData<WorkspaceEditDTO | undefined>(
          workspaceCacheKey,
          workspace => {
            if (!workspace || !mutateBody) return workspace;

            return {
              ...workspace,
              title:
                mutateBody.editedWorkspace.title === undefined ||
                mutateBody.editedWorkspace.title === null
                  ? workspace.title
                  : mutateBody.editedWorkspace.title,
            };
          },
        );
      },
      onSuccess: (data, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );
  const workspaceId = router.query.workspaceId
    ? (router.query.workspaceId as string)
    : workspace?.id;
  return {
    workspace,
    workspaceId,
    isFetching,
    createWorkspace,
    isCreatingWorkspace,
    editWorkspace,
    isEditWorkspaceSuccess,
  };
};
export default useWorkSpace;
