import { useQuery } from '@tanstack/react-query';

import BackendService from '../services/backendService';
import { useDeleteTemplate } from './useDeleteTemplate';
import useDuplicateTemplate from './useDuplicateTemplate';
export const templatesCacheKey = 'templates';
function useTemplates(parentId?: string) {
  const cacheKey = parentId ? [templatesCacheKey, parentId] : [templatesCacheKey];

  const { data: templates, isFetching: isFetchingTemplates } = useQuery(
    cacheKey,
    async _ => {
      const templates = await BackendService.instance.template.listTemplates(parentId);
      return templates;
    },
    { staleTime: 1000 * 60 * 5 },
  );

  const { deleteTemplate, isDeletingTemplate } = useDeleteTemplate({
    currentCacheKey: cacheKey,
  });

  const { duplicateTemplate, isDuplicatingTemplate } = useDuplicateTemplate(parentId);

  return {
    templates,
    isFetchingTemplates,
    deleteTemplate,
    isDeletingTemplate,
    duplicateTemplate,
    isDuplicatingTemplate,
  };
}
export default useTemplates;
